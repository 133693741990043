import "./Remind.css";
import React from "react";
import RemindWindow from "../../Components/RemindWindow";

const Remind = () => {
  document.title = "Przypomnij hasło - AJ Finanse"
  return (
    <React.Fragment>
      <RemindWindow />
    </React.Fragment>
  );
};

export default Remind;
