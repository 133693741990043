import * as React from "react";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import {
  DataGrid,
  GridActionsCellItem,
  plPL,
  GridRowParams,
  GridRowId,
} from "@mui/x-data-grid";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CheckIcon from "@mui/icons-material/Check";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Autocomplete } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useHttpRequest } from "../../Hooks/request-hook";
import { useForm } from "../../Hooks/form-hook";
import { AuthContext } from "../../Context/auth-context";
import ColumnMenu from "./ColumnMenu";
import Alert from "@mui/material/Alert";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import ClientMeetings from "./ClientMeetings";
import AddIcon from "@mui/icons-material/Add";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ConstructionOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useSearchParams, useParams } from "react-router-dom";

const init = {
  date: "",
  status: "Niewykonane",
  note: "",
  dateEdit: "",
  statusEdit: "",
  noteEdit: "",
};

const filter = {
  date: "",
  status: "",
};

export default function Callendar(props) {
  moment.locale();
  const auth = React.useContext(AuthContext);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const { isLoading, error, request } = useHttpRequest();
  const [user, setUser] = React.useState({});
  const [tableData, setTableData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [filteredData, setFilteredData] = React.useState([]);
  const [date, setDate] = React.useState(Date.now());
  const [dateEdit, setDateEdit] = React.useState(Date.now());
  const [params] = useSearchParams();
  const [checked, setChecked] = React.useState(false);
  const [allData, setAllData] = React.useState([]);
  const [callendar, setCallendar] = React.useState({});
  const [dataPast, setDataPast] = React.useState([]);
  const [currentData, setCurrentData] = React.useState([]);
  let filteredapps = [];
  let navigate = useNavigate();
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);

  const mapData = (data) => {
    const datenow = new Date();
    const datanotcompleted = data.filter((x) => {
      const date = new Date(x.date);
      return x.status === "Niewykonane";
    });

    const datacompleted = data.filter((x) => {
      const date = new Date(x.date);
      return x.status === "Wykonane";
    });

    const resultnotcompleted = datanotcompleted.map((x) => {
      let date = moment(x.date).format("DD.MM.YYYY, HH:mm");

      return {
        id: x._id,
        date: date,
        _date: x.date,
        note: x.note,
        status: x.status,
      };
    });

    const resultcompleted = datacompleted.map((x) => {
      let date = moment(x.date).format("DD.MM.YYYY, HH:mm");

      return {
        id: x._id,
        date: date,
        _date: x.date,
        note: x.note,
        status: x.status,
      };
    });

    let iteration = 0;

    resultcompleted.sort((a, b) => {
      const datea = new Date(a._date);
      const dateb = new Date(b._date);
      iteration = iteration + 1;
      return datea < dateb ? 1 : -1;
    });

    resultnotcompleted.sort((a, b) => {
      const datea = new Date(a._date);
      const dateb = new Date(b._date);
      return datea > dateb ? 1 : -1;
    });

    let result = [...resultnotcompleted, ...resultcompleted];


    // result.sort((a, b) => {
    //   const datea = new Date(a._date);
    //   const dateb = new Date(b._date);
    //   iteration = iteration + 1;
    //   return new Date(a._date) > new Date(b._date) ? 1 : -1;
    // });

    // result.sort((a, b) => {
    //   const datea = new Date(a._date);
    //   const dateb = new Date(b._date);
    //   iteration = iteration + 1;
    //   if (
    //     datea.getUTCDate() === datenow.getUTCDate() &&
    //     datea.getUTCMonth() === datenow.getUTCMonth() &&
    //     datea.getUTCFullYear() === datenow.getUTCFullYear() &&
    //     dateb.getUTCDate() === datenow.getUTCDate() &&
    //     dateb.getUTCMonth() === datenow.getUTCMonth() &&
    //     dateb.getUTCFullYear() === datenow.getUTCFullYear()
    //   ) {
    //     if (a.status === "Niewykonane" && b.status === "Wykonane") return -1;
    //     if (a.status === "Wykonane" && b.status === "Niewykonane") return 1;
    //     if (a.status === b.status) {
    //       if (new Date(a._date) > new Date(b._date)) return 1;
    //       else {
    //         if (new Date(a._date) < new Date(b._date)) return -1;
    //         else return 0;
    //       }
    //     }
    //   } else return 0;
    // });

    const datapast = data.filter((x) => {
      const date = new Date(x.date);
      return date - datenow < 0;
    });

    const resultpast = datapast.map((x) => {
      let date = moment(x.date).format("DD.MM.YYYY, HH:mm");

      return {
        id: x._id,
        date: date,
        _date: x.date,
        note: x.note,
        status: x.status,
      };
    });

    setDataPast(resultpast);
    setCurrentData(result);
    setAllData(result);
    setTableData(result);
    setFilteredData(result);
    setLoaded(true);
  };

  //   const mapAdd = (data) => {
  //     const result = data.map((x) => {
  //       let date = moment(x.date).format("DD.MM.YYYY, HH:mm");

  //       return {
  //         id: x._id,
  //         date: date,
  //         note: x.note,
  //         status: x.status,
  //       };
  //     });
  //     let temp;
  //     temp = [...allData, result[0]];
  //     values.date = "";
  //     values.note = "";
  //     values.status = "Niewykonane";
  //     setAllData(temp);
  //     setTableData(temp);
  //     filterApps(temp);
  //   };

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request(`/callendar/${auth.id}`, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        mapData(data.callendar);
      } catch (err) {}
    };
    call();
  }, [request]);

  const filterApps = (result) => {
    setLoaded(false);

    const data = result.filter((x) => {
      const date = new Date(x._date);
      const datenow = new Date();
      return (
        (filter.status === "" ? true : x.status === filter.status) &&
        (filter.date === ""
          ? true
          : filter.date === ">"
          ? date - datenow > 0
          : filter.date === "<"
          ? date - datenow < 0
          : date.getUTCDate() === datenow.getUTCDate() &&
            date.getUTCMonth() === datenow.getUTCMonth() &&
            date.getUTCFullYear() === datenow.getUTCFullYear())
      );
    });
    setFilteredData(data);
    setLoaded(true);
  };

  const validateAdd = (fieldValues = values) => {
    let temp = { ...errors };
    if ("status" in fieldValues) {
      temp.status = fieldValues.status ? "" : "Wybierz status";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const columns = [
    { field: "id", headerName: "ID", width: 40 },
    {
      field: "date",
      headerName: "Data",
      type: "date",
      flex: 1,
      minWidth: 140,
    },
    { field: "_date", headerName: "_DATA", type: "date", flex: 1 },
    { field: "note", headerName: "Notatka", flex: 2, minWidth: 220 },
    { field: "status", headerName: "Status", flex: 1, minWidth: 140 },
    {
      field: "",
      headerClassName: "lastcolumnSeparator",
      renderCell: (cellValues) => {
        return (
          <IconButton
            color="inherit"
            component={Button}
            onClick={async (event) => {
              event.ignore = true;
              setMsg("");
              setErrorMsg("");
              try {
                const response = await request(
                  `/callendar/changestatus/${cellValues.id}`,
                  "PATCH",
                  null,
                  {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + auth.token,
                  }
                );
                setMsg("Status został zmieniony.");
                setErrorMsg("");
                window.location.reload();
              } catch (err) {
                setMsg("");
                setErrorMsg("Nie można zmienić statusu.");
              }
            }}
          >
            {cellValues.row.status === "Niewykonane" ? (
              <CheckIcon />
            ) : (
              <ClearIcon />
            )}
          </IconButton>
          // <Button
          //   variant="contained"
          //   color="primary"
          //   onClick={(event) => {
          //     handleClick(event, cellValues);
          //   }}
          // >
          //   Print
          // </Button>
        );
      },
    },
    {
      field: " ",
      headerClassName: "lastcolumnSeparator",
      renderCell: (cellValues) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => {
              event.ignore = true;
              setCallendar({ id: cellValues.id });
              setDateEdit(cellValues.row._date);
              values.noteEdit = cellValues.row.note;
              values.statusEdit = cellValues.row.status;
              handleClickOpenEdit();
            }}
          >
            Edytuj
          </Button>
        );
      },
    },
  ];

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleSubmitAdd = async (event) => {
    event.preventDefault();
    setErrors({});
    if (validateAdd()) {
      try {
        const response = await request(
          "/callendar/new",
          "POST",
          JSON.stringify({
            date: date,
            note: values.note,
            status: values.status,
            worker: auth.id,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setMsg("Wpis został dodany.");
        setErrorMsg("");
        handleCloseAdd();
        window.location.reload();
      } catch (err) {
        setMsg("");
        setErrorMsg(
          "Nie można dodać nowego wpisu, sprawdź poprawność wprowadzonych danych."
        );
        handleCloseAdd();
      }
    }
  };

  const handleSubmitEdit = async (event) => {
    event.preventDefault();
    setErrors({});
    try {
      const response = await request(
        `/callendar/update/${callendar.id}`,
        "PATCH",
        JSON.stringify({
          date: dateEdit,
          note: values.noteEdit,
          status: values.statusEdit,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      window.location.reload();
      setMsg("Wpis został zmieniony.");
      setErrorMsg("");
      handleCloseEdit();
    } catch (err) {
      setMsg("");
      setErrorMsg(
        "Nie można edytować wpisu, sprawdź poprawność wprowadzonych danych."
      );
      handleCloseEdit();
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          "& .font": { fontWeight: "600" },
          "& .past": {
            color: "rgba(243, 12, 12, 1)",
            fontWeight: "600",
          },
        }}
      >
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid item xs={12} md={8}>
            <h2>Kalendarz</h2>
          </Grid>
          <Grid item xs={12} md={4} justifyContent="flex-end">
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="big"
              onClick={handleClickOpenAdd}
              startIcon={<AddIcon />}
            >
              Dodaj
            </Button>
          </Grid>
        </Grid>
        {loaded && (
          <React.Fragment>
            <Grid container spacing={2} columns={12}>
              <Grid xs={6} md={6} item>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Data</InputLabel>
                  <Select
                    labelId="cdatefilter"
                    id="cdatefilter"
                    label="Data"
                    value={filter.date}
                    onChange={(e) => {
                      filter.date = e.target.value;
                      if (e.target.value === "<") {
                        setFilteredData(dataPast);
                      } else {
                        if (e.target.value === "") {
                          setFilteredData(currentData);
                        } else {
                          filterApps(tableData);
                        }
                      }
                    }}
                    name="cdatefilter"
                  >
                    <MenuItem value="">Brak</MenuItem>
                    <MenuItem value=">">Nadchodzące</MenuItem>
                    <MenuItem value="===">Dziś</MenuItem>
                    <MenuItem value="<">Przeszłe</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6} md={6} item>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Status</InputLabel>
                  <Select
                    labelId="cstatusfilter"
                    id="cstatusfilter"
                    label="Status"
                    name="cstatusfilter"
                    value={filter.status}
                    onChange={(e) => {
                      filter.status = e.target.value;
                      filterApps(tableData);
                    }}
                  >
                    <MenuItem value="">Brak</MenuItem>
                    <MenuItem value="Niewykonane">Niewykonane</MenuItem>
                    <MenuItem value="Wykonane">Wykonane</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <DataGrid
              autoHeight
              autoWidth
              rows={filteredData}
              columns={columns}
              onRowClick={(data, event) => {
                //navigate(`/meetings/${data.row.id}/${props.id}`);
                if (!event.ignore) {
                  setCallendar({ id: data.row.id });
                  setDateEdit(data.row._date);
                  values.noteEdit = data.row.note;
                  values.statusEdit = data.row.status;
                  handleClickOpenEdit();
                }
              }}
              disableSelectionOnClick
              getCellClassName={(params) => {
                const datenow = new Date();
                const date = new Date(params.row._date);
                if (params.row.status === "Niewykonane" && date - datenow < 0)
                  return "past";
                else return "font";
              }}
              // getCellClassName={(params) => {
              //   if (params.field === "status") {
              //     return params.value === "Oczekujące" ? "active" : "blocked";
              //   }
              // }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
              columnVisibilityModel={{
                _date: false,
              }}
              components={{
                ColumnMenu: ColumnMenu,
              }}
            />
          </React.Fragment>
        )}
        <Dialog open={openAdd} onClose={handleCloseAdd}>
          <DialogTitle>Dodaj nowy wpis</DialogTitle>
          <DialogContent>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={moment.locale("pl")}
            >
              <FormControl margin="normal" fullWidth>
                <DateTimePicker
                  variant="inline"
                  margin="normal"
                  fullWidth
                  label="Data"
                  value={date}
                  onChange={(e) => {
                    setDate(e);
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </FormControl>
              <TextField
                multiline
                margin="dense"
                id="outlined-multiline-flexible"
                label="Notatka"
                name="note"
                fullWidth
                variant="standard"
                value={values.note}
                onChange={handleInputChange}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  labelId="status"
                  id="status"
                  label="Status"
                  name="status"
                  value={values.status}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Niewykonane">Niewykonane</MenuItem>
                  <MenuItem value="Wykonane">Wykonane</MenuItem>
                </Select>
              </FormControl>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAdd}>Anuluj</Button>
            <Button onClick={handleSubmitAdd}>Dodaj</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edytuj wpis</DialogTitle>
          <DialogContent>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={moment.locale("pl")}
            >
              <FormControl margin="normal" fullWidth>
                <DateTimePicker
                  variant="inline"
                  margin="normal"
                  fullWidth
                  label="Data"
                  value={dateEdit}
                  onChange={(e) => {
                    setDateEdit(e);
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </FormControl>
              <TextField
                multiline
                margin="dense"
                id="outlined-multiline-flexible"
                label="Notatka"
                name="noteEdit"
                fullWidth
                variant="standard"
                value={values.noteEdit}
                onChange={handleInputChange}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  labelId="statusEdit"
                  id="statusEdit"
                  label="Status"
                  name="statusEdit"
                  value={values.statusEdit}
                  onChange={handleInputChange}
                >
                  <MenuItem value="Niewykonane">Niewykonane</MenuItem>
                  <MenuItem value="Wykonane">Wykonane</MenuItem>
                </Select>
              </FormControl>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Anuluj</Button>
            <Button onClick={handleSubmitEdit}>Zapisz</Button>
          </DialogActions>
        </Dialog>
        {errorMsg && (
          <Alert
            variant="filled"
            severity="error"
            onClose={() => {
              setErrorMsg("");
            }}
          >
            {errorMsg}
          </Alert>
        )}
        {msg && (
          <Alert
            variant="filled"
            severity="success"
            onClose={() => {
              setMsg("");
            }}
          >
            {msg}
          </Alert>
        )}
      </Box>
    </div>
  );
}
