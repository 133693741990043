import * as React from "react";
import {
  DataGrid,
  GridActionsCellItem,
  plPL,
  GridRowParams,
  GridRowId,
} from "@mui/x-data-grid";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Autocomplete } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useHttpRequest } from "../../Hooks/request-hook";
import { AuthContext } from "../../Context/auth-context";
import { useForm } from "../../Hooks/form-hook";
import ColumnMenu from "./ColumnMenu";
import Alert from "@mui/material/Alert";
import moment from "moment";
import "moment/locale/pl";
import { Link } from "react-router-dom";

const init = {
  instType: "",
  inst: "",
  amount: "",
  answer: "",
  note: "",
};

export default function ClientApplications(props) {
  moment.locale("pl");
  const auth = React.useContext(AuthContext);
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);
  const { isLoading, error, request } = useHttpRequest();
  const [tableData, setTableData] = React.useState([]);
  const [inst, setInst] = React.useState([]);
  const [banks, setBanks] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [app, setApp] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState("");
  const [inputInst, setInputInst] = React.useState("");
  const [openEdit, setOpenEdit] = React.useState(false);

  const mapData = (data, meetings) => {
    let iteration = 0;
    const result = data.map((x) => {
      let date;
      const meeting = meetings.find((y) => {
        return y._id === x.meetingid;
      });

      let meetingdate = moment(meeting.date).format("DD.MM.YYYY, hh:mm");

      let note;
      if (x.date) {
        date = moment(x.date).format("DD.MM.YYYY");
      } else {
        date = "";
      }
      if (x.note) {
        note = x.note;
      } else {
        note = "";
      }
      iteration = iteration + 1;

      return {
        meetingid: x.meetingid,
        meeting: meetingdate,
        id: iteration,
        _id: x.id,
        date: date,
        inst: x.inst,
        _instType: x.instType,
        amount: x.amount,
        _note: note,
        answer: x.answer,
      };
    });

    setLoaded(true);
    setTableData(result);
  };

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request(
          `/clients/applications/${props.id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        mapData(data.applications, data.meetings);
        setInst(data.institutions);
        setBanks(data.banks);
      } catch (err) {}
    };
    call();
  }, [request]);

  const validateEdit = (fieldValues = values) => {
    let temp = { ...errors };
    if ("instType" in fieldValues) {
      temp.instType = fieldValues.instType
        ? ""
        : "Wybierz poprawny typ instytucji";
    }
    if ("inst" in fieldValues) {
      temp.inst = fieldValues.inst ? "" : "Wybierz instytucję";
    }
    if ("answer" in fieldValues) {
      temp.answer = fieldValues.answer ? "" : "Wybierz odpowiedź";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleSubmitEdit = async (event) => {
    event.preventDefault();
    setErrors({});
    if (validateEdit()) {
      try {
        const response = await request(
          `/clients/application/edit/${app.id}`,
          "PATCH",
          JSON.stringify({
            answer: values.answer,
            amount: values.amount,
            note: values.note,
            instType: values.instType,
            inst: values.inst,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        window.location.reload();
        handleCloseEdit();
      } catch (err) {
        setErrorMsg(
          "Nie można edytować wniosku, sprawdź poprawność wprowadzonych danych."
        );
        handleCloseEdit();
      }
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 40 },
    {
      field: "_meetingid",
      headerName: "_Spotkanie",
      flex: 1,
    },
    {
      field: "meeting",
      headerName: "Spotkanie",
      flex: 1,
      minWidth: 130,
      renderCell: (cell) => {
        return (
          <Link
            to={`/meetings/${cell.row.meetingid}/${props.id}`}
            style={{ color: "#000" }}
            onClick={(event) => {
              event.ignore = true;
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    { field: "_id", headerName: "_ID", width: 0 },
    {
      field: "date",
      headerName: "Data złożenia",
      type: "date",
      flex: 1,
      minWidth: 160,
    },
    { field: "inst", headerName: "Nazwa instytucji", flex: 1, minWidth: 200 },
    { field: "_instType", headerName: "_INSTTYPE", width: 0 },
    { field: "amount", headerName: "Kwota", flex: 1, minWidth: 100 },
    {
      field: "answer",
      headerName: "Status",
      flex: 1,
      minWidth: 130,
    },
    { field: "_note", headerName: "_NOTE", width: 0 },
    {
      field: "actions",
      headerName: "Akcja",
      flex: 1,
      minWidth: 60,
      type: "actions",
      getActions: (GridRowParams) => [
        <GridActionsCellItem
          label="Zobacz szczegóły"
          onClick={() => {
            setApp({
              id: GridRowParams.getValue(GridRowParams.id, "_id"),
              date: GridRowParams.getValue(GridRowParams.id, "date"),
            });
            values.inst = GridRowParams.getValue(GridRowParams.id, "inst");
            values.instType = GridRowParams.getValue(
              GridRowParams.id,
              "_instType"
            );
            values.amount = GridRowParams.getValue(GridRowParams.id, "amount");
            values.answer = GridRowParams.getValue(GridRowParams.id, "answer");
            values.note = GridRowParams.getValue(GridRowParams.id, "_note");
            setInputInst(GridRowParams.getValue(GridRowParams.id, "inst"));
            handleClickOpenEdit();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Usuń"
          onClick={async () => {
            try {
              const response = await request(
                `/clients/application/delete/${GridRowParams.getValue(
                  GridRowParams.id,
                  "_id"
                )}`,
                "DELETE",
                null,
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
              window.location.reload();
              setErrorMsg("");
            } catch (err) {
              setErrorMsg("Wystąpił błąd, spróbuj ponownie później.");
            }
          }}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <h3>Zbiorcza historia wszystkich wniosków</h3>
      {loaded && (
        <DataGrid
          autoHeight
          autoWidth
          rows={tableData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          onRowClick={(data, event) => {
            if (!event.ignore) {
              setApp({
                id: data.row._id,
                date: data.row.date,
              });
              values.inst = data.row.inst;
              values.instType = data.row._instType;
              values.amount = data.row.amount;
              values.answer = data.row.answer;
              values.note = data.row._note;
              setInputInst(data.row.inst);
              handleClickOpenEdit();
            }
          }}
          disableSelectionOnClick
          localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
          columnVisibilityModel={{
            _meetingid: false,
            _id: false,
            _note: false,
            _instType: false,
          }}
          components={{
            ColumnMenu: ColumnMenu,
          }}
        />
      )}
      {banks && inst && (
        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Szczegóły wniosku</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <InputLabel>Typ instytucji</InputLabel>
              <Select
                labelId="instType"
                id="instType"
                value={values.instType}
                label="Typ instytucji"
                onChange={handleInputChange}
                name="instType"
                fullWidth
              >
                <MenuItem value={values.instType}>{values.instType}</MenuItem>;
                {values.instType === "Bank" ? (
                  <MenuItem value="Instytucja pozabankowa">
                    Instytucja pozabankowa
                  </MenuItem>
                ) : (
                  <MenuItem value="Bank">Bank</MenuItem>
                )}
              </Select>
            </FormControl>

            {/* <FormControl fullWidth margin="normal">
              <InputLabel>Instytucja</InputLabel>
              <Select
                labelId="inst"
                id="inst"
                value={values.inst}
                label="Instytucj"
                onChange={handleInputChange}
                name="inst"
                fullWidth
              >
                {values.instType === "Bank"
                  ? banks.map((bank, index) => {
                      return (
                        <MenuItem key={index} value={bank.name}>
                          {bank.name}
                        </MenuItem>
                      );
                    })
                  : inst.map((inst, index) => {
                      return (
                        <MenuItem key={index} value={inst.name}>
                          {inst.name}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl> */}
            <FormControl fullWidth margin="normal">
              <Autocomplete
                noOptionsText="Brak opcji"
                value={values.inst}
                onChange={(e, value) => {
                  if (value === null) {
                    value = "";
                  }
                  values.inst = value;
                }}
                inputValue={inputInst}
                onInputChange={(e, value) => {
                  if (value === null) {
                    value = "";
                  }
                  setInputInst(value);
                }}
                options={
                  values.instType === "Bank"
                    ? banks.map((bank, index) => {
                        return bank.name;
                      })
                    : values.instType === "Instytucja pozabankowa"
                    ? inst.map((inst, index) => {
                        return inst.name;
                      })
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Instytucja" />
                )}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                labelId="answer"
                id="answer"
                value={values.answer}
                label="Odpowiedź"
                onChange={handleInputChange}
                name="answer"
                fullWidth
              >
                <MenuItem value="Wysłano">Wysłano</MenuItem>
                <MenuItem value="Odmowa">Odmowa</MenuItem>
                <MenuItem value="Oferta">Oferta</MenuItem>
                <MenuItem value="Wypłacony">Wypłacony</MenuItem>
              </Select>
            </FormControl>
            <TextField
              autoFocus
              margin="dense"
              id="amount"
              label="Kwota"
              name="amount"
              fullWidth
              variant="standard"
              value={values.amount}
              onChange={handleInputChange}
              error={errors.amount}
              helperText={errors.amount}
            />
            <TextField
              multiline
              margin="dense"
              id="outlined-multiline-flexible"
              label="Notatka"
              name="note"
              fullWidth
              variant="standard"
              value={values.note || ""}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cofnij</Button>
            <Button onClick={handleSubmitEdit}>Zapisz</Button>
          </DialogActions>
        </Dialog>
      )}
      {errorMsg && (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setErrorMsg("");
          }}
        >
          {errorMsg}
        </Alert>
      )}
    </div>
  );
}
