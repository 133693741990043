import * as React from "react";
import {
  DataGrid,
  GridActionsCellItem,
  plPL,
  GridRowParams,
  GridRowId,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useHttpRequest } from "../../Hooks/request-hook";
import { useForm } from "../../Hooks/form-hook";
import { AuthContext } from "../../Context/auth-context";
import ColumnMenu from "./ColumnMenu";
import Alert from "@mui/material/Alert";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";

const init = {
  email: "",
  emailEdit: "",
  name: "",
  nameEdit: "",
};

export default function Workers() {
  moment.locale();
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const { isLoading, error, request } = useHttpRequest();
  const [user, setUser] = React.useState({});
  const [tableData, setTableData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");
  const auth = React.useContext(AuthContext);
  const [pageSize, setPageSize] = React.useState(5);
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);

  const mapData = (data, clients) => {
    let iteration = 0;
    const result = data.map((x) => {
      const count = clients.filter((y) => {
        return y.worker === x._id;
      }).length;
      let status;
      if (x.active === false) {
        status = "Zablokowany";
      }
      if (x.active === true) {
        status = "Aktywny";
      }
      iteration = iteration + 1;

      let email = x.email.slice(0, 5) + "...";

      let date = moment(x.date).format("DD.MM.YYYY");

      return {
        id: iteration,
        _id: x._id,
        date: date,
        name: x.name,
        email: email,
        _emailFull: x.email,
        active: status,
        clients: count,
      };
    });
    setLoaded(true);
    setTableData(result);
  };

  const mapAdd = (data) => {
    let isData = false;
    const result = data.map((x) => {
      let status = "Aktywny";
      let date = moment(x.date).format("DD.MM.YYYY");

      let email = x.email.slice(0, 5) + "...";

      isData = true;
      return {
        id: tableData.length + 1,
        _id: x._id,
        date: date,
        name: x.name,
        email: email,
        _emailFull: x.email,
        active: status,
        clients: 0,
      };
    });
    if (isData === true) {
      let temp;
      temp = [...tableData, result[0]];
      values.name = "";
      values.email = "";
      setLoaded(true);
      setTableData(temp);
    }
  };

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request("/system/users", "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        mapData(data.users, data.clients);
      } catch (err) {}
    };
    call();
  }, [request]);

  const validateAdd = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      temp.email = /^\S+@\S+\.\S+$/.test(fieldValues.email)
        ? ""
        : "Podaj poprawny adres email";
    }
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "Podaj imię i nazwisko";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateEdit = (fieldValues = values) => {
    let temp = { ...errors };
    if ("emailEdit" in fieldValues) {
      temp.email = /^\S+@\S+\.\S+$/.test(fieldValues.emailEdit)
        ? ""
        : "Podaj poprawny adres email";
    }
    if ("nameEdit" in fieldValues) {
      temp.name = fieldValues.nameEdit ? "" : "Podaj imię i nazwisko";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 40,
      renderCell: (cell) => {
        return (
          <Button
            onClick={() => {
              setUser({
                id: cell.row._id,
              });
              values.nameEdit = cell.row.name;
              values.emailEdit = cell.row._emailFull;

              handleClickOpenEdit();
            }}
            style={{ color: "#000" }}
          >
            {cell.value}
          </Button>
        );
      },
    },
    { field: "_id", headerName: "_ID", width: 0 },
    {
      field: "date",
      headerName: "Data dodania",
      type: "date",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "name",
      headerName: "Imię i nazwisko",
      flex: 1,
      minWidth: 200,
      renderCell: (cell) => {
        return (
          <Button
            onClick={(event) => {
              event.ignore = true;
              setUser({
                id: cell.row._id,
              });
              values.nameEdit = cell.row.name;
              values.emailEdit = cell.row._emailFull;

              handleClickOpenEdit();
            }}
            style={{ color: "#000" }}
          >
            {cell.value}
          </Button>
        );
      },
    },
    {
      field: "email",
      headerName: "Adres e-mail",
      type: "email",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "_emailFull",
      headerName: "emailFull",
      type: "email",
      width: 0,
    },
    {
      field: "active",
      headerName: "Status",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "clients",
      headerName: "Przypisanych klientów",
      flex: 1,
      minWidth: 160,
      type: "number",
      renderCell: (cell) => {
        return (
          <Link
            to={`/clients?worker=${cell.row.name}`}
            style={{ color: "#000" }}
            onClick={(event) => {
              event.ignore = true;
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      field: "actions",
      headerName: "Akcja",
      flex: 1,
      minWidth: 60,
      type: "actions",
      getActions: (GridRowParams) => [
        <GridActionsCellItem
          label={
            GridRowParams.getValue(GridRowParams.id, "active") === "Aktywny"
              ? "Zablokuj"
              : "Odblokuj"
          }
          onClick={async () => {
            setMsg("");
            setErrorMsg("");
            try {
              const response = await request(
                `/system/blockuser/${GridRowParams.getValue(
                  GridRowParams.id,
                  "_id"
                )}`,
                "PATCH",
                null,
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
              const index = tableData.findIndex((x) => {
                return (
                  x._id === GridRowParams.getValue(GridRowParams.id, "_id")
                );
              });
              if (tableData[index].active === "Aktywny") {
                tableData[index].active = "Zablokowany";
              } else {
                tableData[index].active = "Aktywny";
              }
              setMsg(
                `Status opiekuna ${GridRowParams.getValue(
                  GridRowParams.id,
                  "name"
                )} został zmieniony.`
              );
              setErrorMsg("");
            } catch (err) {
              setMsg("");
              setErrorMsg("Wystąpił błąd, spróbuj ponownie później.");
            }
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edytuj"
          onClick={() => {
            setUser({
              id: GridRowParams.getValue(GridRowParams.id, "_id"),
            });
            values.nameEdit = GridRowParams.getValue(GridRowParams.id, "name");
            values.emailEdit = GridRowParams.getValue(
              GridRowParams.id,
              "_emailFull"
            );

            handleClickOpenEdit();
          }}
          showInMenu
        />,
      ],
    },
  ];

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleSubmitAdd = async (event) => {
    event.preventDefault();
    setErrors({});
    if (validateAdd()) {
      try {
        const response = await request(
          "/system/newuser",
          "POST",
          JSON.stringify({
            name: values.name,
            email: values.email,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        const newworker = [response.user];
        mapAdd(newworker);
        setMsg("Opiekun został dodany.");
        setErrorMsg("");
        handleCloseAdd();
      } catch (err) {
        setMsg("");
        setErrorMsg(
          "Nie można dodać nowego użytkownika, sprawdź poprawność wprowadzonych danych."
        );
        handleCloseAdd();
      }
    }
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleSubmitEdit = async (event) => {
    event.preventDefault();
    setErrors({});
    if (validateEdit()) {
      try {
        const response = await request(
          `/system/edituser/${user.id}`,
          "PATCH",
          JSON.stringify({
            name: values.nameEdit,
            email: values.emailEdit,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        const index = tableData.findIndex((x) => {
          return x._id === user.id;
        });
        tableData[index].name = values.nameEdit;
        let email = values.emailEdit.slice(0, 5) + "...";
        tableData[index].email = email;
        tableData[index]._emailFull = values.emailEdit;
        values.nameEdit = "";
        values.emailEdit = "";
        handleCloseEdit();
        setMsg("Dane zostały zmienione");
        setErrorMsg("");
      } catch (err) {
        setMsg("");
        setErrorMsg(
          "Nie można edytować użytkownika, sprawdź poprawność wprowadzonych danych."
        );
        handleCloseEdit();
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          "& .active": {
            color: "#4ef300",
            fontWeight: "bold",
          },
          "& .blocked": {
            color: "#e40000",
            fontWeight: "bold",
          },
          "& .font": { fontWeight: "600" },
        }}
      >
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid item xs={12} md={8}>
            <h3>Konta opiekunów</h3>
          </Grid>
          <Grid item xs={12} md={4} justifyContent="flex-end">
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="small"
              onClick={handleClickOpenAdd}
              startIcon={<AddIcon />}
            >
              Dodaj
            </Button>
          </Grid>
        </Grid>
        {loaded && (
          <DataGrid
            autoHeight
            autoWidth
            rows={tableData}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            getRowClassName={() => {
              return "font";
            }}
            onRowClick={(data, event) => {
              if (!event.ignore) {
                setUser({ id: data.row._id });
                values.nameEdit = data.row.name;
                values.emailEdit = data.row._emailFull;
                handleClickOpenEdit();
              }
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "active", sort: "asc" }],
              },
            }}
            disableSelectionOnClick
            getCellClassName={(params) => {
              if (params.field === "active") {
                return params.value === "Aktywny" ? "active" : "blocked";
              }
            }}
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            columnVisibilityModel={{
              _id: false,
              _emailFull: false,
            }}
            components={{
              ColumnMenu: ColumnMenu,
            }}
          />
        )}
        <Dialog open={openAdd} onClose={handleCloseAdd}>
          <DialogTitle>Dodaj nowego pracownika</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              label="Imię i nazwisko"
              name="name"
              fullWidth
              variant="standard"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              helperText={errors.name}
            />
            <TextField
              required
              margin="dense"
              id="email"
              label="Adres e-mail"
              name="email"
              type="e-mail"
              fullWidth
              variant="standard"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email}
              helperText={errors.email}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAdd}>Anuluj</Button>
            <Button onClick={handleSubmitAdd}>Dodaj</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edytuj konto pracownika</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="nameEdit"
              label="Imię i nazwisko"
              name="nameEdit"
              fullWidth
              variant="standard"
              value={values.nameEdit}
              onChange={handleInputChange}
              error={errors.nameEdit}
              helperText={errors.nameEdit}
            />
            <TextField
              required
              margin="dense"
              id="emailEdit"
              label="Adres e-mail"
              name="emailEdit"
              type="e-mail"
              fullWidth
              variant="standard"
              value={values.emailEdit}
              onChange={handleInputChange}
              error={errors.emailEdit}
              helperText={errors.emailEdit}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cofnij</Button>
            <Button onClick={handleSubmitEdit}>Zapisz</Button>
          </DialogActions>
        </Dialog>
        {errorMsg && (
          <Alert
            variant="filled"
            severity="error"
            onClose={() => {
              setErrorMsg("");
            }}
          >
            {errorMsg}
          </Alert>
        )}
        {msg && (
          <Alert
            variant="filled"
            severity="success"
            onClose={() => {
              setMsg("");
            }}
          >
            {msg}
          </Alert>
        )}
      </Box>
    </div>
  );
}
