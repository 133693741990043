import * as React from "react";
import {
  DataGrid,
  GridActionsCellItem,
  plPL,
  GridRowParams,
  GridRowId,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useHttpRequest } from "../../Hooks/request-hook";
import { useForm } from "../../Hooks/form-hook";
import { AuthContext } from "../../Context/auth-context";
import ColumnMenu from "./ColumnMenu";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";

const init = {
  name: "",
  nameEdit: "",
};

export default function Banks() {
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [bank, setBank] = React.useState({});
  const { isLoading, error, request } = useHttpRequest();
  const [tableData, setTableData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const auth = React.useContext(AuthContext);
  const [msg, setMsg] = React.useState("");
  const [pageSize, setPageSize] = React.useState(5);
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);

  const mapData = (data, applications) => {
    let iteration = 0;
    let isData = false;
    const result = data.map((x) => {
      let status;
      iteration = iteration + 1;
      if (x.active === false) {
        status = "Zablokowany";
      }
      if (x.active === true) {
        status = "Aktywny";
      }
      const count = applications.filter((y) => {
        return y.inst === x.name;
      }).length;

      isData = true;
      return {
        id: iteration,
        _id: x._id,
        name: x.name,
        active: status,
        amount: count,
      };
    });
    if (isData === true) {
      let temp;
      temp = result;
      setLoaded(true);
      setTableData(temp);
    }
  };

  const mapAdd = (data) => {
    let isData = false;
    const result = data.map((x) => {
      let status = "Aktywny";
      const count = 0;

      isData = true;
      return {
        id: tableData.length + 1,
        _id: x._id,
        name: x.name,
        active: status,
        amount: count,
      };
    });
    if (isData === true) {
      let temp;
      temp = [...tableData, result[0]];
      values.name = "";
      setLoaded(true);
      setTableData(temp);
    }
  };

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request("/system/banks", "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        mapData(data.banks, data.applications);
      } catch (err) {}
    };
    call();
  }, [request]);

  const validateAdd = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "Podaj nazwę banku";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateEdit = (fieldValues = values) => {
    let temp = { ...errors };
    if ("nameEdit" in fieldValues) {
      temp.name = fieldValues.nameEdit ? "" : "Podaj nazwę banku";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const columns = [
    { field: "id", headerName: "ID", width: 40 },
    { field: "_id", headerName: "_ID", width: 0 },
    { field: "name", headerName: "Nazwa banku", flex: 1, minWidth: 320 },
    {
      field: "active",
      headerName: "Status",
      flex: 1,
      minWidth: 190,
    },
    {
      field: "amount",
      headerName: "Złożonych wniosków",
      flex: 1,
      minWidth: 160,
      type: "number",
      // renderCell: (cell) => {
      //   return (
      //     <Link
      //       to={`/meetings?type=Bank&inst=${cell.row.name}`}
      //       style={{ color: "#000" }}
      //       onClick={(event) => {
      //         event.ignore = true;
      //       }}
      //     >
      //       {cell.value}
      //     </Link>
      //   );
      // },
    },
    {
      field: "actions",
      headerName: "Akcja",
      flex: 1,
      minWidth: 60,
      type: "actions",
      getActions: (GridRowParams) => [
        <GridActionsCellItem
          label={
            GridRowParams.getValue(GridRowParams.id, "active") === "Aktywny"
              ? "Zablokuj"
              : "Odblokuj"
          }
          onClick={async () => {
            setMsg("");
            setErrorMsg("");
            try {
              const response = await request(
                `/system/blockbank/${GridRowParams.getValue(
                  GridRowParams.id,
                  "_id"
                )}`,
                "PATCH",
                null,
                {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + auth.token,
                }
              );
              const index = tableData.findIndex((x) => {
                return (
                  x._id === GridRowParams.getValue(GridRowParams.id, "_id")
                );
              });
              if (tableData[index].active === "Aktywny") {
                tableData[index].active = "Zablokowany";
              } else {
                tableData[index].active = "Aktywny";
              }
              setMsg(
                `Status banku ${GridRowParams.getValue(
                  GridRowParams.id,
                  "name"
                )} został zmieniony.`
              );
              setErrorMsg("");
            } catch (err) {
              setMsg("");
              setErrorMsg("Wystąpił błąd, spróbuj ponownie później.");
            }
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edytuj"
          onClick={() => {
            setBank({
              id: GridRowParams.getValue(GridRowParams.id, "_id"),
            });
            values.nameEdit = GridRowParams.getValue(GridRowParams.id, "name");

            handleClickOpenEdit();
          }}
          showInMenu
        />,
      ],
    },
  ];

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleSubmitAdd = async (event) => {
    event.preventDefault();
    setErrors({});
    if (validateAdd()) {
      try {
        const response = await request(
          "/system/newbank",
          "POST",
          JSON.stringify({
            name: values.name,
            active: true,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        // window.location.reload();
        const newbank = [response.bank];
        mapAdd(newbank);
        setMsg("Bank został dodany.");
        setErrorMsg("");
        handleCloseAdd();
      } catch (err) {
        setMsg("");
        setErrorMsg(
          "Nie można dodać nowego banku, sprawdź poprawność wprowadzonych danych."
        );
        handleCloseAdd();
      }
    }
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleSubmitEdit = async (event) => {
    event.preventDefault();
    setErrors({});
    if (validateEdit()) {
      try {
        const response = await request(
          `/system/editbank/${bank.id}`,
          "PATCH",
          JSON.stringify({
            name: values.nameEdit,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        const index = tableData.findIndex((x) => {
          return x._id === bank.id;
        });
        tableData[index].name = values.nameEdit;
        values.nameEdit = "";
        handleCloseEdit();
        setMsg("Dane zostały zmienione");
        setErrorMsg("");
      } catch (err) {
        setMsg("");
        setErrorMsg(
          "Nie można edytować banku, sprawdź poprawność wprowadzonych danych."
        );
        handleCloseEdit();
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          "& .active": {
            color: "#4ef300",
            fontWeight: "bold",
          },
          "& .blocked": {
            color: "#e40000",
            fontWeight: "bold",
          },
          "& .font": { fontWeight: "600" },
        }}
      >
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid item xs={12} md={8}>
            <h3>Lista instytucji bankowych</h3>
          </Grid>
          <Grid item xs={12} md={4} justifyContent="flex-end">
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="small"
              onClick={handleClickOpenAdd}
              startIcon={<AddIcon />}
            >
              Dodaj
            </Button>
          </Grid>
        </Grid>
        {loaded && (
          <DataGrid
            autoHeight
            autoWidth
            rows={tableData}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            onRowClick={(data, event) => {
              if (!event.ignore) {
                setBank({ id: data.row._id });
                values.nameEdit = data.row.name;
                handleClickOpenEdit();
              }
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "active", sort: "asc" }],
              },
            }}
            getCellClassName={(params) => {
              if (params.field === "active") {
                return params.value === "Aktywny" ? "active" : "blocked";
              }
            }}
            getRowClassName={() => {
              return "font";
            }}
            columnVisibilityModel={{
              _id: false,
            }}
            components={{
              ColumnMenu: ColumnMenu,
            }}
            disableSelectionOnClick
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
        <Dialog open={openAdd} onClose={handleCloseAdd}>
          <DialogTitle>Dodaj nową instytucję</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              label="Nazwa banku"
              fullWidth
              variant="standard"
              name="name"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              helperText={errors.name}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAdd}>Anuluj</Button>
            <Button onClick={handleSubmitAdd}>Dodaj</Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openEdit} onClose={handleCloseEdit}>
          <DialogTitle>Edytuj instytucję</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="nameEdit"
              label="Nazwa banku"
              fullWidth
              variant="standard"
              name="nameEdit"
              value={values.nameEdit}
              onChange={handleInputChange}
              error={errors.nameEdit}
              helperText={errors.nameEdit}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cofnij</Button>
            <Button onClick={handleSubmitEdit}>Zapisz</Button>
          </DialogActions>
        </Dialog>
        {errorMsg && (
          <Alert
            variant="filled"
            severity="error"
            onClose={() => {
              setErrorMsg("");
            }}
          >
            {errorMsg}
          </Alert>
        )}
        {msg && (
          <Alert
            variant="filled"
            severity="success"
            onClose={() => {
              setMsg("");
            }}
          >
            {msg}
          </Alert>
        )}
      </Box>
    </div>
  );
}
