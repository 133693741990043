import "./App.css";
import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route, Link } from "react-router-dom";
import Button from "@mui/material/Button";

import Login from "./Pages/Starting/Login";
import PassReset from "./Pages/Starting/PassReset";
import Remind from "./Pages/Starting/Remind";
import Settings from "./Pages/Main/Settings";
import Account from "./Pages/Main/Account";
import Clients from "./Pages/Main/Clients";
import Client from "./Pages/Main/Client";
import Meetings from "./Pages/Main/Meetings";
import Meeting from "./Pages/Main/Meeting";
import Callendar from "./Pages/Main/Callendar";

import { AuthContext } from "./Context/auth-context";

const App = () => {
  const [token, setToken] = useState(false);
  const [tokenExpiration, setTokenExpiration] = useState();
  const [admin, setAdmin] = useState(false);
  const [userId, setUserId] = useState();
  const [loaded, setLoaded] = useState(false);

  let timer;

  const applogin = useCallback((id, admin, token, date) => {
    const expire = date || new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
    setTokenExpiration(expire);
    setToken(token);
    setUserId(id);
    setAdmin(admin);
    localStorage.setItem(
      "data",
      JSON.stringify({
        userId: id,
        token: token,
        admin: admin,
        expires: expire.toISOString(),
      })
    );
  }, []);

  const applogout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setTokenExpiration(null);
    setAdmin(null);
    localStorage.removeItem("data");
  }, []);

  useEffect(() => {
    if (token) {
      const time = tokenExpiration.getTime() - new Date().getTime();
      timer = setTimeout(applogout, time);
    } else {
      clearTimeout(timer);
    }
  }, [token, applogout, tokenExpiration]);

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("data"));
    if (storage && storage.token && new Date(storage.expires) > new Date()) {
      applogin(
        storage.userId,
        storage.admin,
        storage.token,
        new Date(storage.expires)
      );
    }
    setLoaded(true);
  }, [applogin]);

  let routes;

  if (token) {
    if (admin) {
      routes = (
        <React.Fragment>
          <Route exact path="/system" element={<Settings />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/clients/:id" element={<Client />} />
          <Route path="/clients" element={<Clients />} />
          <Route exact path="/meetings/:id/:client" element={<Meeting />} />
          <Route path="/callendar" element={<Callendar />} />
          <Route path="*" element={<Settings />} />
        </React.Fragment>
      );
    } else {
      routes = (
        <React.Fragment>
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/clients/:id" element={<Client />} />
          <Route path="/clients" element={<Clients />} />
          <Route exact path="/meetings/:id/:client" element={<Meeting />} />
          <Route path="/callendar" element={<Callendar />} />
          <Route path="*" element={<Account />} />
        </React.Fragment>
      );
    }
  } else {
    routes = (
      <React.Fragment>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/resetpass/:type/:token" element={<PassReset />} />
        <Route exact path="/remindpass" element={<Remind />} />
        <Route path="/*" element={<Login />} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {loaded && (
        <AuthContext.Provider
          value={{
            isLoggedIn: !!token,
            token: token,
            id: userId,
            admin: admin,
            login: applogin,
            logout: applogout,
          }}
        >
          <Routes>{routes}</Routes>
        </AuthContext.Provider>
      )}
    </React.Fragment>
  );
};

export default App;
