import React from "react";
import SystemWindow from "../../Components/MainWindows/SystemWindow";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Workers from "../../Components/Tables/Workers";

const Settings = () => {
  document.title = "System - AJ Finanse"
  return (
    <React.Fragment>
      <SystemWindow name="Ustawienia systemu" />
    </React.Fragment>
  );
};

export default Settings;
