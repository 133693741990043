import React, { useState } from "react";

export function useForm(init, validateOnChange = false, validate) {
  const [values, setValues] = useState(init);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) {
      validate({[name]: value})
    }
  };
  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  };
}
