import React from "react";
import ClientsWindow from "../../Components/MainWindows/ClientsWindow";

const Clients = () => {
  document.title = "Lista klientów - AJ Finanse"
  return (
    <React.Fragment>
      <ClientsWindow name="Lista klientów"/>
    </React.Fragment>
  );
};

export default Clients;
