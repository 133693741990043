import React from "react";
import MeetingsWindow from "../../Components/MainWindows/MeetingsWindow";

const Meetings = () => {
  document.title = "Lista spotkań - AJ Finanse"
  return (
    <React.Fragment>
      <MeetingsWindow name="Lista spotkań"/>
    </React.Fragment>
  );
};

export default Meetings;
