import { useState, useCallback, useRef, useEffect } from "react";

export const useHttpRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const activeRequest = useRef([]);

  const request = useCallback(
    async (url, method = "GET", body = null, headers = {}) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeRequest.current.push(httpAbortCtrl);
      try {
        const response = await fetch("/api" + url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal,
        });
        const data = await response.json();
        activeRequest.current = activeRequest.current.filter(reqCtrl => reqCtrl !== httpAbortCtrl)

        if (!response.ok) {
          throw new Error(data.message);
        }
        
        return data;
      } catch (err) {
        setError(err);
        console.log(err);
        throw err;
      }
    },
    []
  );

  useEffect(() => {
    return () => {
      activeRequest.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { isLoading, error, request };
};