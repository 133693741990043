import "./Login.css";
import React from "react";
import LoginWindow from "../../Components/LoginWindow";

const Login = () => {
  document.title = "Logowanie - AJ Finanse"
  return (
    <React.Fragment>
      <LoginWindow />
    </React.Fragment>
  );
};

export default Login;
