import "./PassReset.css";
import React from "react";
import PassResetWindow from "../../Components/PassResetWindow";

const PassReset = () => {
  document.title = "Zresetuj hasło - AJ Finanse"
  return (
    <React.Fragment>
      <PassResetWindow />
    </React.Fragment>
  );
};

export default PassReset;
