import "./PassResetWindow.css";
import React, {
  useCallback,
  useReducer,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { useHttpRequest } from "../Hooks/request-hook";
import { useForm } from "../Hooks/form-hook";
import { AuthContext } from "../Context/auth-context";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSearchParams, useParams } from "react-router-dom";

const theme = createTheme();

const init = {
  password: "",
  password2: "",
};

const PassResetWindow = () => {
  const { isLoading, request } = useHttpRequest();
  const auth = React.useContext(AuthContext);
  const [email, setEmail] = useState("");
  const { type, token } = useParams();
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("password" in fieldValues) {
      temp.password =
        values.password.length >= 6 ? "" : "Hasło jest zbyt krótkie";
    }
    if ("password2" in fieldValues) {
      temp.password2 =
        values.password === values.password2 ? "" : "Hasła nie są podobne";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request(`/user/verify/${type}/${token}`, "GET");
        setEmail(data.email);
      } catch (err) {}
    };
    call();
  }, [request]);

  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      try {
        const response = await request(
          `/user/updatepassword/${type}`,
          "PATCH",
          JSON.stringify({
            email: email,
            password: values.password,
          }),
          { "Content-Type": "application/json" }
        );
        setError("");
        setMsg(response.msg + " Kliknij aby się zalogować");
        values.password = "";
        values.password2 = "";
      } catch (err) {
        setMsg("");
        setError("Wystąpił błąd, spróbuj ponownie później.");
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Zresetuj Hasło
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Nowe hasło"
              type="password"
              id="password"
              value={values.password}
              onChange={handleInputChange}
              error={errors.password}
              helperText={errors.password}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password2"
              label="Powtórz nowe hasło"
              type="password"
              id="password2"
              value={values.password2}
              onChange={handleInputChange}
              error={errors.password2}
              helperText={errors.password2}
            />
            {error && (
              <Alert
                variant="filled"
                severity="error"
                onClose={() => {
                  setError("");
                }}
              >
                {error}
              </Alert>
            )}
            {msg && (
              <Alert
                variant="filled"
                severity="success"
                onClose={() => {
                  setMsg("");
                }}
              >
                <Link to="/" variant="body2" style={{ textDecoration: 'none', color: "white" }}>
                  {msg}
                </Link>
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Resetuj
            </Button>
            {msg && (
              <Grid container>
                <Grid item xs>
                  <Link to="/" variant="body2">
                    Zaloguj się
                  </Link>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PassResetWindow;
