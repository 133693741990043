import "./PassResetWindow.css";
import React, { useCallback, useReducer, useContext } from "react";
import { Link } from "react-router-dom";

import { VALIDATOR_REQUIRE, VALIDATOR_EMAIL } from "../Validators";
import { useHttpRequest } from "../Hooks/request-hook";
import { useForm } from "../Hooks/form-hook";
import { AuthContext } from "../Context/auth-context";

import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

const theme = createTheme();

const init = {
  email: "",
};

const RemindWindow = () => {
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isAdmin, setIsAdmin] = React.useState(false);
  const { isLoading, error, request } = useHttpRequest();
  const [msg, setMsg] = React.useState("");
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      temp.email = /^\S+@\S+\.\S+$/.test(fieldValues.email)
        ? ""
        : "Podaj poprawny adres email";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    init,
    true,
    validate
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    let type;
    if (isAdmin) {
      type = "admin";
    } else {
      type = "user";
    }
    if (validate()) {
      try {
        const response = await request(
          `/user/reset/${type}`,
          "PATCH",
          JSON.stringify({
            email: values.email,
          }),
          { "Content-Type": "application/json" }
        );
        setErrorMsg("");
        setMsg(response.msg);
        values.email = "";
      } catch (err) {
        setMsg("");
        setErrorMsg("Wystąpił błąd, sprawdź poprawność adresu e-mail.");
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Przypomnij hasło
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email}
              helperText={errors.email}
              autoFocus
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setIsAdmin(!isAdmin);
                  }}
                />
              }
              label="Jestem administratorem"
            />
            {/* <Select
              labelId="accounttype"
              id="accounttype"
              fullWidth
              value={type}
              onChange={handleChange}
            >
              <MenuItem value="user">Użytkownik</MenuItem>
              <MenuItem value="admin">Administrator</MenuItem>
            </Select> */}
            {errorMsg && (
              <Alert
                variant="filled"
                severity="error"
                onClose={() => {
                  setErrorMsg("");
                }}
              >
                {errorMsg}
              </Alert>
            )}
            {msg && (
              <Alert
                variant="filled"
                severity="success"
                onClose={() => {
                  setMsg("");
                }}
              >
                {msg}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Wyślij
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/" variant="body2">
                  Zaloguj się
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default RemindWindow;
