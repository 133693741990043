import * as React from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { DataGrid, GridActionsCellItem, plPL } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useHttpRequest } from "../../Hooks/request-hook";
import { useForm } from "../../Hooks/form-hook";
import { AuthContext } from "../../Context/auth-context";
import ColumnMenu from "./ColumnMenu";
import Alert from "@mui/material/Alert";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
// import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Box } from "@mui/material";

const init = {
  email: "",
  name: "",
  pnumber: "",
  category: "",
  worker: "",
};

const filter = {
  category: "",
  instType: "",
  inst: "",
  answer: "",
  name: "",
  optionWorker: "",
  inputWorker: "",
  datemeetingfrom: "",
  datemeetinguntil: "",
  datefrom: "",
  dateuntil: "",
  status: "",
};

export default function Clients() {
  moment.locale();
  const auth = React.useContext(AuthContext);
  const [openAdd, setOpenAdd] = React.useState(false);
  // const [openEdit, setOpenEdit] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const { request } = useHttpRequest();
  // const [user, setUser] = React.useState({});
  // const [optionCategory, setOptionCategory] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [activeCategories, setActiveCategories] = React.useState([]);
  const [workers, setWorkers] = React.useState([]);
  const [client, setClient] = React.useState({});
  const [banks, setBanks] = React.useState([]);
  const [inst, setInst] = React.useState([]);
  const [inputCategory, setInputCategory] = React.useState("");
  const [applications, setApplications] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [params] = useSearchParams();
  const [inputWorker, setInputWorker] = React.useState("");
  const [optionWorker, setOptionWorker] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [currentState, setCurrentState] = React.useState("");
  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateUntil, setDateUntil] = React.useState(null);
  const [dateMeetingFrom, setDateMeetingFrom] = React.useState(null);
  const [dateMeetingUntil, setDateMeetingUntil] = React.useState(null);
  const [meetings, setMeetings] = React.useState([]);
  const [csvDataClient, setCsvDataClient] = React.useState([]);
  const [csvDataApp, setCsvDataApp] = React.useState([]);
  // let csvDataClient = [{}];
  // let csvDataApp = [{}];
  const csvLink = React.useRef();
  const csvLink2 = React.useRef();
  let optionWorkerFilter = "";
  let inputWorkerFilter = "";
  let data = [];
  let apps = [];
  let filteredapps = [];
  let filteredmeetings = [];
  let navigate = useNavigate();
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);

  const mapData = (data, workers, meetings, apps) => {
    let iteration = 0;
    const toggle = auth.admin
      ? false
      : (localStorage.getItem("toggle") === "true" ? true : false) || false;

    setChecked(toggle);
    setCurrentState("before map");
    const result = data.map((x) => {
      let date = moment(x.date).format("DD.MM.YYYY");
      let email = x.email;
      setCurrentState("in map " + iteration + " " + x.name + " pesel");
      let pesel;
      if (x.pesel) {
        pesel = x.pesel.slice(0, 6) + "...";
      }
      setCurrentState("in map " + iteration + " " + x.name + " pnum");
      let pnumber;
      if (x.pnumber) {
        pnumber = x.pnumber;
      } else {
        pnumber = "";
      }
      iteration = iteration + 1;

      setCurrentState("in map " + iteration + " " + x.name + " opiekun");
      let worker;
      workers.map((y) => {
        if (y.id === x.worker) {
          worker = y.name;
        }
      });
      if (!worker) {
        worker = "";
      }

      let city;
      if (x.city) {
        city = x.city;
      } else {
        city = "";
      }

      let postalcode;
      if (x.postalcode) {
        postalcode = x.postalcode;
      } else {
        postalcode = "";
      }

      let src;
      if (x.source) {
        src = x.source;
      } else {
        src = "";
      }

      setCurrentState("in map " + iteration + " " + x.name + " spotkania");
      const count = meetings.filter((y) => {
        return y.clientid === x._id;
      }).length;

      setCurrentState("in map " + iteration + " " + x.name + " return");

      return {
        id: iteration,
        _id: x.id,
        date: date,
        _date: x.date,
        name: x.name,
        pesel: pesel,
        _pesel: x.pesel,
        pnumber: pnumber,
        _pnumber: x.pnumber,
        email: email,
        _emailFull: x.email,
        worker: worker,
        workerid: x.worker,
        category: x.category,
        _idnr: x.idnr,
        meetings: count,
        status: x.status,
        city: city,
        postalcode: postalcode,
        lastactivity: x.lastactivity,
        src: src,
      };
    });
    const filters = Object.fromEntries([...params]);
    filter.instType =
      filters.type || localStorage.getItem("insttypefilter") || "";
    filter.category =
      filters.category || localStorage.getItem("categoryfilter") || "";
    filter.inst = filters.inst || localStorage.getItem("instfilter") || "";
    filter.answer = localStorage.getItem("answerfilter") || "";
    filter.name = filters.worker || localStorage.getItem("namefilter") || "";
    filter.optionWorker = localStorage.getItem("optionworkerfilter") || "";
    filter.inputWorker = localStorage.getItem("inputworkerfilter") || "";
    filter.datefrom = localStorage.getItem("cdatefrom") || "";
    filter.dateuntil = localStorage.getItem("cdateuntil") || "";
    filter.datemeetingfrom = localStorage.getItem("cdatemeetingfrom") || "";
    filter.datemeetinguntil = localStorage.getItem("cdatemeetinguntil") || "";
    filter.status = localStorage.getItem("cstatus") || "";

    localStorage.setItem("insttypefilter", filter.instType);
    localStorage.setItem("categoryfilter", filter.category);
    localStorage.setItem("instfilter", filter.inst);
    localStorage.setItem("answerfilter", filter.answer);
    localStorage.setItem("namefilter", filter.name);
    localStorage.setItem("optionworkerfilter", filter.optionWorker);
    localStorage.setItem("inputworkerfilter", filter.optionWorker);
    localStorage.setItem("cdatefrom", filter.datefrom);
    localStorage.setItem("cdateuntil", filter.dateuntil);
    localStorage.setItem("cdatemeetingfrom", filter.datemeetingfrom);
    localStorage.setItem("cdatemeetinguntil", filter.datemeetinguntil);
    localStorage.setItem("cstatus", filter.status);
    // setLoaded(true);
    setDateFrom(filter.datefrom || null);
    setDateUntil(filter.dateuntil || null);
    setDateMeetingFrom(filter.datemeetingfrom || null);
    setDateMeetingUntil(filter.datemeetinguntil || null);
    setCurrentState("after map");
    data = result;
    result.sort((a, b) => {
      const datea = new Date(a.lastactivity);
      const dateb = new Date(b.lastactivity);
      return datea < dateb ? 1 : -1;
    });
    setAllData(result);
    if (toggle) {
      let workerClients = result.filter((x) => {
        return x.workerid === auth.id;
      });
      setTableData(workerClients);
      filterApps(workerClients, apps, meetings, workers);
    } else {
      setTableData(result);
      filterApps(result, apps, meetings, workers);
    }
  };

  const mapAdd = (data) => {
    const result = data.map((x) => {
      let date = moment(x.date).format("DD.MM.YYYY");

      let email = x.email;

      let pesel;
      if (x.pesel) {
        pesel = x.pesel.slice(0, 6) + "...";
      }
      let pnumber;
      if (x.pnumber) {
        pnumber = x.pnumber;
      } else {
        pnumber = "";
      }
      let worker;
      workers.map((y) => {
        if (y.id === x.worker) {
          worker = y.name;
        }
      });
      if (!worker) {
        worker = "";
      }
      return {
        id: tableData.length + 1,
        _id: x.id,
        date: date,
        name: x.name,
        pesel: pesel,
        _pesel: x.pesel,
        pnumber: pnumber,
        _pnumber: x.pnumber,
        email: email,
        _emailFull: x.email,
        worker: worker,
        workerid: x.worker,
        category: x.category,
        _idnr: x.idnr,
        meetings: 0,
        satatus: x.status,
        city: "",
        postalcode: "",
        src: "",
        lastactivity: x.lastactivity,
      };
    });

    let temp;
    temp = [...allData, result[0]];
    temp.sort((a, b) => {
      const datea = new Date(a.lastactivity);
      const dateb = new Date(b.lastactivity);
      return datea < dateb ? 1 : -1;
    });
    values.name = "";
    values.email = "";
    values.pnumber = "";
    values.category = "";
    values.worker = "";
    setOptionWorker("");
    setInputWorker("");
    setLoaded(true);
    setAllData(temp);
    setTableData(temp);
    filterApps(temp, applications, meetings, workers);
  };

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request(
          `/clients/get/${auth.admin}/${auth.id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setActiveCategories(data.activeCategories);
        setCategories(data.categories);
        setWorkers(data.users);
        setBanks(data.banks);
        setInst(data.inst);
        setMeetings(data.meetings);
        setApplications(data.applications);
	mapData(data.clients, data.users, data.meetings, data.applications);
      } catch (err) {}
    };
    call();
  }, [auth.id, auth.admin, auth.token, request]);

  const filterData = (result, applications, meetings, workers) => {
    let dataf = result.filter((x) => {
      const date = new Date(x._date);
      const datenow = new Date();
      const dateuntil = new Date(filter.dateuntil);
      const name = x.name.toLowerCase();
      const email = x._emailFull.toLowerCase();
      let pesel;
      if (!x._pesel) {
        pesel = " ";
      } else {
        pesel = x._pesel;
      }

      let idnr;
      if (!x._idnr) {
        idnr = " ";
      } else {
        idnr = x._idnr;
      }

      let pnumber;
      if (!x._pnumber) {
        pnumber = " ";
      } else {
        pnumber = x._pnumber;
      }

      let worker;
      if (!x.worker) {
        worker = " ";
      } else {
        worker = x.worker.toLowerCase();
      }
      // if (filter.name === "") {
      //   if (filter.category) {
      //     return x.category === filter.category;
      //   } else {
      //     return true;
      //   }
      // } else {
      //   return (
      //     (name.includes(filter.name.toLowerCase()) ||
      //       pesel.includes(filter.name) ||
      //       idnr.includes(filter.name) ||
      //       email.includes(filter.name.toLowerCase()) ||
      //       pnumber.toString().includes(filter.name.toLowerCase())) &&
      //     x.category === filter.category
      //   );
      // }
      return (
        (name.includes(filter.name.toLowerCase()) ||
          pesel.includes(filter.name) ||
          idnr.includes(filter.name) ||
          email.includes(filter.name.toLowerCase()) ||
          worker.includes(filter.name.toLowerCase()) ||
          pnumber.toString().includes(filter.name.toLowerCase()) ||
          x.date.includes(filter.name)) &&
        (filter.status === "" ? true : x.status.includes(filter.status)) &&
        (filter.category === ""
          ? true
          : x.category.toLowerCase().includes(filter.category.toLowerCase())) &&
        (filter.optionWorker === ""
          ? filter.inputWorker === ""
            ? true
            : x.worker.includes(filter.inputWorker)
          : x.worker ===
            filter.optionWorker.slice(0, filter.optionWorker.search(" #"))) &&
        (filter.datefrom === "" ? true : x._date > filter.datefrom) &&
        (filter.dateuntil === ""
          ? true
          : x._date < filter.dateuntil ||
            (date.getUTCDate() === dateuntil.getUTCDate() &&
              date.getUTCMonth() === dateuntil.getUTCMonth() &&
              date.getUTCFullYear() === dateuntil.getUTCFullYear()))
      );
    });
    const newdataf = dataf.map((x, i) => {
      return { ...x, id: i + 1 };
    });
    let temp = newdataf.map((x) => {
      return {
        id: x.id,
        fname: x.name.split(" ", 2)[0],
	lname: x.name.split(" ", 2)[1],
        pnumber: x.pnumber,
        category: x.category,
        city: x.city,
        postalcode: x.postalcode,
        src: x.src,
        worker: x.worker,
      };
    });
    setCsvDataClient(temp);

    temp = applications.map((x) => {
      return {
        id: x.clientid,
        mid: x.meetingid,
        inst: x.inst,
        amount: x.amount,
        answer: x.answer,
      };
    });
    let temp2 = temp.map((x) => {
      let client = newdataf.find((y) => y._id === x.id);
      if (client)
        return {
          fname: client.name.split(" ", 2)[0],
	  lname: client.name.split(" ", 2)[1],
          pnumber: client.pnumber,
          category: client.category,
          city: client.city,
          postalcode: client.postalcode,
          src: client.src,
          ...x,
          id: client.id,
        };
    });
    let filtered = temp2.filter((x) => {
      return x;
    });
    let temp3 = filtered.map((x) => {
      let meeting = meetings.find((y) => {
        return x.mid === y.id;
      });
      let worker;
      let date;
      if (meeting) {
        worker = workers.find((y) => {
          return y.id === meeting.worker;
        });
      if (!worker) {
	worker = { name: "" };
      }
        date = moment(meeting.date).format("DD.MM.YYYY");
      } else {
        date = "";
        worker = { name: "" };
      }
      return { ...x, mid: date, worker: worker.name };
    });
    temp3.sort((a, b) => {
      return a.id - b.id;
    });
    //console.log(temp3);
    setCsvDataApp(temp3);
    setLoaded(true);
    setFilteredData(newdataf);
  };

  const headerClient = [
    { label: "Lp.", key: "id" },
    { label: "Imię", key: "fname" },
    { label: "Nazwisko", key: "lname" },
    { label: "Telefon", key: "pnumber" },
    { label: "Kategoria", key: "category" },
    { label: "Miejscowość", key: "city" },
    { label: "Kod pocztowy", key: "postalcode" },
    { label: "Źródło", key: "src" },
    { label: "Opiekun", key: "worker" },
  ];

  const headerApp = [
    { label: "Lp.", key: "id" },
    { label: "Imię", key: "fname" },
    { label: "Nazwisko", key: "lname" },
    { label: "Telefon", key: "pnumber" },
    { label: "Kategoria", key: "category" },
    { label: "Miejscowość", key: "city" },
    { label: "Kod pocztowy", key: "postalcode" },
    { label: "Źródło", key: "src" },
    { label: "Data spotkania", key: "mid" },
    { label: "Wniosek", key: "inst" },
    { label: "Kwota", key: "amount" },
    { label: "Status", key: "answer" },
    { label: "Doradca", key: "worker" },
  ];

  const getCsvClient = async () => {
    csvLink.current.link.click();
  };

  const filterDataCombined = (result, applications, meetings, workers) => {
    const data = result.filter((x) => {
      const date = new Date(x._date);
      const datenow = new Date();
      const dateuntil = new Date(filter.dateuntil);
      const name = x.name.toLowerCase();
      const email = x._emailFull.toLowerCase();
      let app;
      let meet;
      let pesel;
      let pnumber;
      if (!x._pesel) {
        pesel = " ";
      } else {
        pesel = x._pesel;
      }

      let idnr;
      if (!x._idnr) {
        idnr = " ";
      } else {
        idnr = x._idnr;
      }

      if (!x._pnumber) {
        pnumber = " ";
      } else {
        pnumber = x._pnumber;
      }

      let worker;
      if (!x.worker) {
        worker = " ";
      } else {
        worker = x.worker.toLowerCase();
      }
      // if (filteredapps.length > 0) {
      //   app = filteredapps.filter((y) => {
      //     return x._id === y.clientid;
      //   });
      //   if (app.length > 0) {
      //     if (filter.name === "") {
      //       if (filter.category) {
      //         return x.category === filter.category;
      //       } else {
      //         return true;
      //       }
      //     } else {
      //       return (
      //         (name.includes(filter.name.toLowerCase()) ||
      //           pesel.includes(filter.name) ||
      //           idnr.includes(filter.name) ||
      //           email.includes(filter.name.toLowerCase()) ||
      //           pnumber.toString().includes(filter.name.toLowerCase())) &&
      //         x.category === filter.category
      //       );
      //     }
      //   }
      // }
      if (filteredapps.length > 0 && filteredmeetings.length > 0) {
        app = filteredapps.filter((y) => {
          return x._id === y.clientid;
        });
        meet = filteredmeetings.filter((y) => {
          return x._id === y.clientid;
        });

        if (app.length && meet.length) {
          return (
            (name.includes(filter.name.toLowerCase()) ||
              pesel.includes(filter.name) ||
              idnr.includes(filter.name) ||
              email.includes(filter.name.toLowerCase()) ||
              worker.includes(filter.name.toLowerCase()) ||
              pnumber.toString().includes(filter.name.toLowerCase()) ||
              x.date.includes(filter.name)) &&
            (filter.status === "" ? true : x.status.includes(filter.status)) &&
            (filter.category === ""
              ? true
              : x.category
                  .toLowerCase()
                  .includes(filter.category.toLowerCase())) &&
            (filter.optionWorker === ""
              ? true
              : x.worker ===
                filter.optionWorker.slice(
                  0,
                  filter.optionWorker.search(" #")
                )) &&
            x.worker.includes(filter.inputWorker.slice(
              0,
              filter.optionWorker.search(" #")
            )) &&
            (filter.datefrom === "" ? true : x._date > filter.datefrom) &&
            (filter.dateuntil === ""
              ? true
              : x._date < filter.dateuntil ||
                (date.getUTCDate() === dateuntil.getUTCDate() &&
                  date.getUTCMonth() === dateuntil.getUTCMonth() &&
                  date.getUTCFullYear() === dateuntil.getUTCFullYear()))
          );
        } else {
          return;
        }
      }

      if (filteredapps.length === 0 && filteredmeetings.length > 0) {
        if (filter.inst || filter.instType || filter.answer) return;
        meet = filteredmeetings.filter((y) => {
          return x._id === y.clientid;
        });
        if (meet.length) {
          return (
            (name.includes(filter.name.toLowerCase()) ||
              pesel.includes(filter.name) ||
              idnr.includes(filter.name) ||
              email.includes(filter.name.toLowerCase()) ||
              worker.includes(filter.name.toLowerCase()) ||
              pnumber.toString().includes(filter.name.toLowerCase()) ||
              x.date.includes(filter.name)) &&
            (filter.status === "" ? true : x.status.includes(filter.status)) &&
            (filter.category === ""
              ? true
              : x.category
                  .toLowerCase()
                  .includes(filter.category.toLowerCase())) &&
            (filter.optionWorker === ""
              ? true
              : x.worker ===
                filter.optionWorker.slice(
                  0,
                  filter.optionWorker.search(" #")
                )) &&
            x.worker.includes(filter.inputWorker.slice(
              0,
              filter.optionWorker.search(" #")
            )) &&
            (filter.datefrom === "" ? true : x._date > filter.datefrom) &&
            (filter.dateuntil === ""
              ? true
              : x._date < filter.dateuntil ||
                (date.getUTCDate() === dateuntil.getUTCDate() &&
                  date.getUTCMonth() === dateuntil.getUTCMonth() &&
                  date.getUTCFullYear() === dateuntil.getUTCFullYear()))
          );
        } else {
          return;
        }
      }

      if (filteredapps.length > 0 && filteredmeetings.length === 0) {
        if (filter.datemeetingfrom || filter.datemeetinguntil) return;
        app = filteredapps.filter((y) => {
          return x._id === y.clientid;
        });

        if (app.length) {
          return (
            (name.includes(filter.name.toLowerCase()) ||
              pesel.includes(filter.name) ||
              idnr.includes(filter.name) ||
              email.includes(filter.name.toLowerCase()) ||
              worker.includes(filter.name.toLowerCase()) ||
              pnumber.toString().includes(filter.name.toLowerCase()) ||
              x.date.includes(filter.name)) &&
            (filter.status === "" ? true : x.status.includes(filter.status)) &&
            (filter.category === ""
              ? true
              : x.category
                  .toLowerCase()
                  .includes(filter.category.toLowerCase())) &&
            (filter.optionWorker === ""
              ? true
              : x.worker ===
                filter.optionWorker.slice(
                  0,
                  filter.optionWorker.search(" #")
                )) &&
            x.worker.includes(filter.inputWorker.slice(
              0,
              filter.optionWorker.search(" #")
            )) &&
            (filter.datefrom === "" ? true : x._date > filter.datefrom) &&
            (filter.dateuntil === ""
              ? true
              : x._date < filter.dateuntil ||
                (date.getUTCDate() === dateuntil.getUTCDate() &&
                  date.getUTCMonth() === dateuntil.getUTCMonth() &&
                  date.getUTCFullYear() === dateuntil.getUTCFullYear()))
          );
        } else {
          return;
        }
      }
    });
    const newdata = data.map((x, i) => {
      return { ...x, id: i + 1 };
    });
    let temp = newdata.map((x) => {
      return {
        id: x.id,
        fname: x.name.split(" ", 2)[0],
	lname: x.name.split(" ", 2)[1],
        pnumber: x.pnumber,
        category: x.category,
        city: x.city,
        postalcode: x.postalcode,
        worker: x.worker,
        src: x.src,
      };
    });
    setCsvDataClient(temp);

    temp = applications.map((x) => {
      return {
        id: x.clientid,
        mid: x.meetingid,
        inst: x.inst,
        amonut: x.amount,
        answer: x.answer,
      };
    });
    let temp2 = temp.map((x) => {
      let client = newdata.find((y) => y._id === x.id);
      if (client)
        return {
          fname: client.name.split(" ", 2)[0],
	  lname: client.name.split(" ", 2)[1],
          pnumber: client.pnumber,
          category: client.category,
          city: client.city,
          postalcode: client.postalcode,
          src: client.src,
          ...x,
          id: client.id,
        };
    });
    let filtered = temp2.filter((x) => {
      return x;
    });
    let temp3 = filtered.map((x) => {
      let meeting = meetings.find((y) => {
        return x.mid === y.id;
      });
      let worker;
      let date;
      if (meeting) {
      	worker = workers.find((y) => {
          return y.id === meeting.worker;
      	});
        date = moment(meeting.date).format("DD.MM.YYYY");
      } else {
	date = "";
      };
      if (!worker) {
      	worker = { name: "" };
      }
      return { ...x, mid: date, worker: worker.name };
    });
    temp3.sort((a, b) => {
      return a.id - b.id;
    });
    setCsvDataApp(temp3);
    setLoaded(true);
    setFilteredData(newdata);
  };

  const filterApps = (result, applications, meetings, workers) => {
    if (
      filter.inst ||
      filter.instType ||
      filter.answer ||
      filter.datemeetingfrom ||
      filter.datemeetinguntil
    ) {
      if (filter.inst || filter.instType || filter.answer) {
        filteredapps = applications.filter((x) => {
          return (
            x.inst.toLowerCase().includes(filter.inst.toLowerCase()) &&
            x.instType.includes(filter.instType) &&
            x.answer.includes(filter.answer)
          );
        });
      }
      if (filter.datemeetingfrom || filter.datemeetinguntil) {
        filteredmeetings = meetings.filter((x) => {
          const date = new Date(x.date);
          const datemeetingfrom = new Date(filter.datemeetingfrom);
          const datemeetinguntil = new Date(filter.datemeetinguntil);
          return (
            (filter.datemeetingfrom === "" ? true : x.date > filter.datemeetingfrom) &&
            (filter.datemeetinguntil === ""
              ? true
              : x.date < filter.datemeetinguntil ||
                (date.getUTCDate() === datemeetinguntil.getUTCDate() &&
                  date.getUTCMonth() === datemeetinguntil.getUTCMonth() &&
                  date.getUTCFullYear() === datemeetinguntil.getUTCFullYear()))
          );
        });
      }
      filterDataCombined(result, applications, meetings, workers);
    } else {
      filterData(result, applications, meetings, workers);
    }
  };

  const validateAdd = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email) {
        temp.email = /^\S+@\S+\.\S+$/.test(fieldValues.email)
          ? ""
          : "Podaj poprawny adres email";
      }
    }
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "Podaj imię i nazwisko";
    }
    // if ("pnumber" in fieldValues) {
    //   temp.pnumber =
    //     fieldValues.pnumber.length >= 9 ? "" : "Podaj poprawny numer telefonu";
    // }
    if ("worker" in fieldValues) {
      if (auth.admin === true) {
        temp.worker = fieldValues.worker ? "" : "Wybierz opiekuna";
      } else {
        values.worker = auth.id;
        temp.worker = "";
      }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const columns = [
    { field: "id", headerName: "Lp.", width: 40, sortable: true, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1, },
    { field: "_id", headerName: "_ID", width: 0 },
    {
      field: "date",
      headerName: "Data dodania",
      type: "date",
      flex: 1,
      minWidth: 130,
    },
    { field: "name", headerName: "Imię i nazwisko", flex: 1, minWidth: 160 },
    { field: "pesel", headerName: "PESEL", flex: 1, minWidth: 60 },
    { field: "_pesel", headerName: "_PESEL", flex: 1 },
    { field: "_idnr", headerName: "_IDNR" },
    { field: "pnumber", headerName: "Telefon", flex: 1, minWidth: 120 },
    { field: "lastactivity", headerName: "lastactivity" },
    {
      field: "email",
      headerName: "Adres e-mail",
      type: "email",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "_emailFull",
      headerName: "emailFull",
      type: "email",
      width: 0,
    },
    { field: "src", headerName: "Źródło", minWidth: 160 },
    { field: "worker", headerName: "Opiekun", flex: 1, minWidth: 160 },
    { field: "workerid", headerName: "Opiekunid" },
    { field: "category", headerName: "Kategoria", flex: 1, minWidth: 130 },
    {
      field: "meetings",
      headerName: "Ilość spotkań",
      flex: 1,
      minWidth: 100,
      // renderCell: (cell) => {
      //   return (
      //     <Link
      //       to={`/meetings?client=${cell.row.name}`}
      //       style={{ color: "#000" }}
      //       onClick={(event) => {
      //         event.ignore = true;
      //       }}
      //     >
      //       {cell.value}
      //     </Link>
      //   );
      // },
    },
    { field: "status", headerName: "Status", flex: 1, minWidth: 180 },
    {
      field: "actions",
      headerName: "Akcja",
      flex: 1,
      minWidth: 60,
      type: "actions",
      getActions: (GridRowParams) => [
        <GridActionsCellItem
          label="Zobacz szczegóły"
          onClick={() => {
            navigate(
              `/clients/${GridRowParams.getValue(GridRowParams.id, "_id")}`
            );
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Pokaż spotkania"
          onClick={() => {
            navigate(
              `/meetings?client=${GridRowParams.getValue(
                GridRowParams.id,
                "name"
              )}`
            );
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Usuń"
          onClick={() => {
            setClient({ id: GridRowParams.getValue(GridRowParams.id, "_id") });
            handleClickOpenDelete();
          }}
          disabled={!auth.admin}
          showInMenu
        />,
      ],
    },
  ];

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClickCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      const response = await request(
        `/clients/delete/${client.id}`,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      handleClickCloseDelete();
      window.location.reload();
      setMsg("Klient został usunięty.");
      setErrorMsg("");
    } catch (err) {
      handleClickCloseDelete();
      setMsg("");
      setErrorMsg("Nie można usunąć klienta, spróbuj ponownie później.");
    }
  };

  const handleSubmitAdd = async (event) => {
    event.preventDefault();
    setErrors({});
    if (validateAdd()) {
      if (auth.admin !== true) {
        values.worker = auth.id;
      }
      try {
        const response = await request(
          "/clients/new",
          "POST",
          JSON.stringify({
            name: values.name,
            email: values.email,
            pnumber: values.pnumber,
            category: values.category,
            worker: values.worker,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        const newclient = [response.client];
        mapAdd(newclient);
        setMsg("Klient został dodany.");
        setErrorMsg("");
        handleCloseAdd();
      } catch (err) {
        setMsg("");
        setErrorMsg(
          "Nie można dodać nowego klienta, sprawdź poprawność wprowadzonych danych."
        );
        handleCloseAdd();
      }
    }
  };

  const handleSwitch = (e) => {
    localStorage.setItem("toggle", e.target.checked);
    if (e.target.checked) {
      let workerClients = tableData.filter((x) => {
        return x.workerid === auth.id;
      });
      setTableData(workerClients);
      filterApps(workerClients, applications, meetings, workers);
    } else {
      setTableData(allData);
      filterApps(allData, applications, meetings, workers);
    }

    setChecked(e.target.checked);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          "& .font": { fontWeight: "600" },
        }}
      >
        <Grid container direction={"row"} alignItems={"center"}>
          {auth.admin ? (
            <React.Fragment>
              <Grid item xs={12} md={3}>
                <h2>Klienci</h2>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  margin="normal"
                  style={{ width: "90%" }}
                  type="submit"
                  variant="contained"
                  size="big"
                  onClick={getCsvClient}
                >
                  Eksport klientów
                </Button>
                <CSVLink
                  data={csvDataClient}
                  headers={headerClient}
                  filename="klienci.csv"
                  className="hidden"
                  ref={csvLink}
                  separator=";"
                  target="_blank"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  margin="normal"
                  style={{ width: "90%" }}
                  type="submit"
                  variant="contained"
                  size="big"
                  onClick={() => {
                    csvLink2.current.link.click();
                  }}
                >
                  Eksport pełny
                </Button>
                <CSVLink
                  data={csvDataApp}
                  headers={headerApp}
                  filename="klienci_wnioski.csv"
                  className="hidden"
                  ref={csvLink2}
                  target="_blank"
                  separator=";"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  margin="normal"
                  type="submit"
                  style={{ width: "90%" }}
                  variant="contained"
                  size="big"
                  onClick={handleClickOpenAdd}
                  startIcon={<AddIcon />}
                >
                  Dodaj
                </Button>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={12} md={9}>
                <h2>Klienci</h2>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  margin="normal"
                  type="submit"
                  style={{ width: "90%" }}
                  variant="contained"
                  size="big"
                  onClick={handleClickOpenAdd}
                  startIcon={<AddIcon />}
                >
                  Dodaj
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <Grid container spacing={2} columns={15}>
          <Grid xs={5} md={3} item>
            {/* <FormControl fullWidth margin="normal">
            <InputLabel>Kategoria</InputLabel>
            <Select
              labelId="categoryfilter"
              id="categoryfilter"
              label="Kategoria"
              value={filter.category}
              onChange={(e) => {
                filter.category = e.target.value;
                filterApps(tableData);
              }}
              name="categoryfilter"
            >
              <MenuItem value="">Brak</MenuItem>
              {categories.map((category, index) => {
                return (
                  <MenuItem key={index} value={category.name}>
                    {category.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}
            <FormControl fullWidth margin="normal">
              <Autocomplete
                noOptionsText="Brak opcji"
                fullWidth
                value={filter.category}
                onChange={(e, value) => {
                  if (value === null) {
                    value = "";
                    localStorage.setItem("categoryfilter", "");
                  }
                  localStorage.setItem("categoryfilter", value);
                  filter.category = value;
                  filterApps(tableData, applications, meetings, workers);
                }}
                inputValue={filter.category}
                onInputChange={(e, value) => {
                  if (value === null) {
                    value = "";
                    localStorage.setItem("categoryfilter", "");
                  }
                  localStorage.setItem("categoryfilter", value);
                  filter.category = value;
                  filterApps(tableData, applications, meetings, workers);
                }}
                options={categories.map((category) => {
                  return category.name;
                })}
                renderInput={(params) => (
                  <TextField {...params} label="Kategoria" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid xs={5} md={3} item>
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                fullWidth
                labelId="statusfilter"
                id="statusfilter"
                label="Status"
                name="statusfilter"
                value={filter.status}
                onChange={(e) => {
                  if (e.target.value === "") {
                    localStorage.setItem("cstatus", "");
                  }
                  localStorage.setItem("cstatus", e.target.value);
                  filter.status = e.target.value;
                  filterApps(tableData, applications, meetings, workers);
                }}
              >
                <MenuItem value="">Brak</MenuItem>
                <MenuItem value="W toku">W toku</MenuItem>
                <MenuItem value="Zakończony">Zakończony</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={5} md={3} item>
            <FormControl fullWidth margin="normal">
              <InputLabel>Typ instytucji</InputLabel>
              <Select
                fullWidth
                labelId="insttypefilter"
                id="insttypefilter"
                label="Typ instytucji"
                name="insttypefilter"
                value={filter.instType}
                onChange={(e) => {
                  if (e.target.value === "") {
                    filter.inst = "";
                    localStorage.setItem("insttypefilter", "");
                  }
                  localStorage.setItem("insttypefilter", e.target.value);
                  filter.instType = e.target.value;
                  filterApps(tableData, applications, meetings, workers);
                }}
              >
                <MenuItem value="">Brak</MenuItem>
                <MenuItem value="Bank">Bank</MenuItem>
                <MenuItem value="Instytucja pozabankowa">
                  Instytucja pozabankowa
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={5} md={3} item>
            {/* <FormControl fullWidth margin="normal">
            <InputLabel>Instytucja</InputLabel>
            <Select
              labelId="instfilter"
              id="instfilter"
              label="Instytucja"
              name="instfilter"
              value={filter.inst}
              onChange={(e) => {
                filter.inst = e.target.value;
                filterApps(tableData);
              }}
            >
              <MenuItem value="">Brak</MenuItem>
              {filter.instType === "Bank " &&
                banks.map((bank, index) => {
                  return (
                    <MenuItem key={index} value={bank.name}>
                      {bank.name}
                    </MenuItem>
                  );
                })}
              {filter.instType === "Instytucja pozabankowa" &&
                inst.map((inst, index) => {
                  return (
                    <MenuItem key={index} value={inst.name}>
                      {inst.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl> */}
            <FormControl fullWidth margin="normal">
              <Autocomplete
                noOptionsText="Brak opcji"
                fullWidth
                value={filter.inst}
                onChange={(e, value) => {
                  if (value === null) {
                    value = "";
                    localStorage.setItem("instfilter", "");
                  }
                  localStorage.setItem("instfilter", value);
                  filter.inst = value;
                  filterApps(tableData, applications, meetings, workers);
                }}
                inputValue={filter.inst}
                onInputChange={(e, value) => {
                  if (value === null) {
                    value = "";
                    localStorage.setItem("instfilter", "");
                  }
                  localStorage.setItem("instfilter", value);
                  filter.inst = value;
                  filterApps(tableData, applications, meetings, workers);
                }}
                options={
                  filter.instType === "Bank"
                    ? banks.map((bank, index) => {
                        return bank.name;
                      })
                    : filter.instType === "Instytucja pozabankowa"
                    ? inst.map((inst, index) => {
                        return inst.name;
                      })
                    : []
                }
                renderInput={(params) => (
                  <TextField {...params} label="Instytucja" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid xs={5} md={3} item>
            <FormControl fullWidth margin="normal">
              <InputLabel>Odpowiedź</InputLabel>
              <Select
                fullWidth
                labelId="answer"
                id="answer"
                label="Odpowiedź"
                value={filter.answer}
                onChange={(e) => {
                  localStorage.setItem("answerfilter", e.target.value);
                  filter.answer = e.target.value;
                  filterApps(tableData, applications, meetings, workers);
                }}
                name="answer"
              >
                <MenuItem value="">Brak</MenuItem>
                <MenuItem value="Wysłano">Wysłano</MenuItem>
                <MenuItem value="Odmowa">Odmowa</MenuItem>
                <MenuItem value="Oferta">Oferta</MenuItem>
                <MenuItem value="Wypłacony">Wypłacony</MenuItem>
                <MenuItem value="Nie wysłano">Nie wysłano</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {auth.admin ? (
            <React.Fragment>
              <Grid xs={5} md={3} item>
                <FormControl fullWidth margin="normal">
                  <Autocomplete
                    noOptionsText="Brak opcji"
                    value={filter.optionWorker}
                    onChange={(e, value) => {
                      if (value === null) {
                        value = { label: "" };
                        localStorage.setItem("optionworkerfilter", "");
                      }
                      filter.optionWorker = value.label;
                      filterApps(tableData, applications, meetings, workers);
                      localStorage.setItem("optionworkerfilter", value.label);
                    }}
                    inputValue={filter.inputWorker}
                    onInputChange={(e, value) => {
                      if (value === null) {
                        value = "";
                        localStorage.setItem("inputworkerfilter", "");
                      }
                      filter.inputWorker = value;
                      filterApps(tableData, applications, meetings, workers);
                      localStorage.setItem("inputworkerfilter", value);
                    }}
                    options={workers.map((worker, index) => {
                      return {
                        label: worker.name + " #" + index,
                        id: worker._id,
                      };
                    })}
                    renderInput={(params) => (
                      <TextField {...params} label="Opiekun" />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid xs={5} md={3} item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="date"
                    variant="standard"
                    value={dateFrom || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      filter.datefrom = e.target.value;
                      setDateFrom(e.target.value);
                      localStorage.setItem("cdatefrom", filter.datefrom);
                      filterApps(tableData, applications, meetings, workers);
                    }}
                    label="Data dodania od:"
                  />
                </FormControl>
              </Grid>
              <Grid xs={5} md={3} item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="date"
                    variant="standard"
                    value={dateUntil || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      filter.dateuntil = e.target.value;
                      setDateUntil(e.target.value);
                      localStorage.setItem("cdateuntil", filter.dateuntil);
                      filterApps(tableData, applications, meetings, workers);
                    }}
                    label="Data dodania do:"
                  />
                </FormControl>
              </Grid>
              <Grid xs={5} md={3} item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="date"
                    variant="standard"
                    value={dateMeetingFrom || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      filter.datemeetingfrom = e.target.value;
                      setDateMeetingFrom(e.target.value);
                      localStorage.setItem(
                        "cdatemeetingfrom",
                        filter.datemeetingfrom
                      );
                      filterApps(tableData, applications, meetings, workers);
                    }}
                    label="Data spotkania od"
                  />
                </FormControl>
              </Grid>
              <Grid xs={5} md={3} item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="date"
                    variant="standard"
                    value={dateMeetingUntil || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      filter.datemeetinguntil = e.target.value;
                      setDateMeetingUntil(e.target.value);
                      localStorage.setItem(
                        "cdatemeetinguntil",
                        filter.datemeetinguntil
                      );
                      filterApps(tableData, applications, meetings, workers);
                    }}
                    label="Data spotkania do"
                  />
                </FormControl>
              </Grid>
              <Grid xs={10} md={3} item>
                <TextField
                  fullWidth
                  margin="normal"
                  id="namefilter"
                  label="Szukaj..."
                  name="namefilter"
                  type="namefilter"
                  variant="standard"
                  value={filter.name}
                  onChange={(e) => {
                    localStorage.setItem("namefilter", e.target.value);
                    filter.name = e.target.value;
                    filterApps(tableData, applications, meetings, workers);
                  }}
                />
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="date"
                    variant="standard"
                    value={dateFrom || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      filter.datefrom = e.target.value;
                      setDateFrom(e.target.value);
                      localStorage.setItem("cdatefrom", filter.datefrom);
                      filterApps(tableData, applications, meetings, workers);
                    }}
                    label="Data dodania od:"
                  />
                </FormControl>
              </Grid>
              <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="date"
                    variant="standard"
                    value={dateUntil || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      filter.dateuntil = e.target.value;
                      setDateUntil(e.target.value);
                      localStorage.setItem("cdateuntil", filter.dateuntil);
                      filterApps(tableData, applications, meetings, workers);
                    }}
                    label="Data dodania do:"
                  />
                </FormControl>
              </Grid>
              <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="date"
                    variant="standard"
                    value={dateMeetingFrom || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      filter.datemeetingfrom = e.target.value;
                      setDateMeetingFrom(e.target.value);
                      localStorage.setItem(
                        "cdatemeetingfrom",
                        filter.datemeetingfrom
                      );
                      filterApps(tableData, applications, meetings, workers);
                    }}
                    label="Data spotkania od"
                  />
                </FormControl>
              </Grid>
              <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="date"
                    variant="standard"
                    value={dateMeetingUntil || ""}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      filter.datemeetinguntil = e.target.value;
                      setDateMeetingUntil(e.target.value);
                      localStorage.setItem(
                        "cdatemeetinguntil",
                        filter.datemeetinguntil
                      );
                      filterApps(tableData, applications, meetings, workers);
                    }}
                    label="Data spotkania do"
                  />
                </FormControl>
              </Grid>
              <Grid xs={6} md={3} item>
                <TextField
                  fullWidth
                  margin="normal"
                  id="namefilter"
                  label="Szukaj..."
                  name="namefilter"
                  type="namefilter"
                  variant="standard"
                  value={filter.name}
                  onChange={(e) => {
                    localStorage.setItem("namefilter", e.target.value);
                    filter.name = e.target.value;
                    filterApps(tableData, applications, meetings, workers);
                  }}
                />
              </Grid>
            </React.Fragment>
          )}
          {!auth.admin && (
            <Grid xs={6} md={6} item>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={checked} onChange={handleSwitch} />}
                  label="Pokaż tylko moich klientów"
                />
              </FormGroup>
            </Grid>
          )}
        </Grid>
        {loaded && (
          <DataGrid
            autoHeight
            autoWidth
            rows={filteredData}
            columns={columns}
            disableSelectionOnClick
            onRowClick={(data, event) => {
              if (!event.ignore) {
                navigate(`/clients/${data.row._id}`);
              }
            }}
            getRowClassName={() => {
              return "font";
            }}
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            columnVisibilityModel={{
              workerid: false,
              lastactivity: false,
              email: false,
              date: false,
              pesel: false,
              _id: false,
              _emailFull: false,
              _idnr: false,
              _pesel: false,
              city: false,
              postalcode: false,
            }}
            components={{
              ColumnMenu: ColumnMenu,
            }}
          />
        )}
        <Dialog open={openDelete} onClose={handleClickCloseDelete}>
          <DialogTitle>{"Czy napewno chcesz usunąć klienta?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Wszystkie dane wraz ze spotkaniami zostaną bezpowrotnie utracone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickCloseDelete}>Nie</Button>
            <Button onClick={handleDelete} autoFocus>
              Tak
            </Button>
          </DialogActions>
        </Dialog>
        {activeCategories && (
          <Dialog open={openAdd} onClose={handleCloseAdd}>
            <DialogTitle>Dodaj nowego klienta</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                label="Imię i nazwisko"
                name="name"
                fullWidth
                variant="standard"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                helperText={errors.name}
              />
              <TextField
                margin="dense"
                id="email"
                label="Adres e-mail"
                name="email"
                type="e-mail"
                fullWidth
                variant="standard"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
                helperText={errors.email}
              />
              <TextField
                autoFocus
                margin="dense"
                id="pnumber"
                label="Numer telefonu"
                name="pnumber"
                fullWidth
                variant="standard"
                value={values.pnumber}
                onChange={handleInputChange}
                error={errors.pnumber}
                helperText={errors.pnumber}
              />
              {/* <FormControl fullWidth margin="normal" error={errors.category}>
            <InputLabel>Kategoria</InputLabel>
            <Select
              required
              labelId="category"
              id="category"
              value={values.category}
              label="Kategoria"
              onChange={handleInputChange}
              name="category"
              fullWidth
            >
              {categories.map((category, index) => {
                return (
                  <MenuItem key={index} value={category.name}>
                    {category.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  noOptionsText="Brak opcji"
                  value={values.category}
                  onChange={(e, value) => {
                    if (value === null) {
                      value = "";
                    }
                    setValues({ ...values, category: value });
                  }}
                  inputValue={inputCategory}
                  onInputChange={(e, value) => {
                    if (value === null) {
                      value = "";
                    }
                    setInputCategory(value);
                  }}
                  options={activeCategories.map((category) => {
                    return category.name;
                  })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Kategoria"
                      error={errors.category}
                      helperText={errors.category}
                    />
                  )}
                />
              </FormControl>
              {auth.admin && (
                // <FormControl fullWidth margin="normal" error={errors.worker}>
                //   <InputLabel>Opiekun</InputLabel>
                //   <Select
                //     required
                //     labelId="worker"
                //     id="worker"
                //     value={values.worker}
                //     label="Opiekun"
                //     onChange={handleInputChange}
                //     name="worker"
                //     fullWidth
                //   >
                //     {workers.map((worker, index) => {
                //       return (
                //         <MenuItem key={index} value={worker._id}>
                //           {worker.name}
                //         </MenuItem>
                //       );
                //     })}
                //   </Select>
                // </FormControl>
                <FormControl fullWidth margin="normal">
                  <Autocomplete
                    noOptionsText="Brak opcji"
                    value={optionWorker}
                    onChange={(e, value) => {
                      if (value === null) {
                        value = { id: "" };
                      }
                      setValues({ ...values, worker: value.id });
                      setOptionWorker(value.label);
                    }}
                    inputValue={inputWorker}
                    onInputChange={(e, value) => {
                      if (value === null) {
                        value = "";
                      }
                      setInputWorker(value);
                    }}
                    options={workers.map((worker, index) => {
                      return {
                        label: worker.name + " #" + index,
                        id: worker._id,
                      };
                    })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Opiekun"
                        error={errors.worker}
                        helperText={errors.worker}
                      />
                    )}
                  />
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAdd}>Anuluj</Button>
              <Button onClick={handleSubmitAdd}>Dodaj</Button>
            </DialogActions>
          </Dialog>
        )}
        {errorMsg && (
          <Alert
            variant="filled"
            severity="error"
            onClose={() => {
              setErrorMsg("");
            }}
          >
            {errorMsg}
          </Alert>
        )}
        {msg && (
          <Alert
            variant="filled"
            severity="success"
            onClose={() => {
              setMsg("");
            }}
          >
            {msg}
          </Alert>
        )}
      </Box>
    </div>
  );
}
