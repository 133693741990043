import React, { useContext } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { AuthContext } from "../../Context/auth-context";
import { Link } from "react-router-dom";

const ListItems = () => {
  const auth = useContext(AuthContext);
  const admin = auth.admin;
  const url = window.location.pathname.split("/")[1];

  return (
    <React.Fragment>
      {admin ? (
        <React.Fragment>
          <ListItemButton
            component={Link}
            to="/callendar"
            selected={url === "callendar"}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Kalendarz" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/clients"
            selected={url === "clients"}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Klienci" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/account"
            selected={url === "account"}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Moje konto" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/system"
            selected={url === "system"}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Ustawienia systemu" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              auth.logout();
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItemButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ListItemButton
            component={Link}
            to="/callendar"
            selected={url === "callendar"}
          >
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Kalendarz" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/clients"
            selected={url === "clients"}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Klienci" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/account"
            selected={url === "account"}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Moje konto" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              auth.logout();
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Wyloguj" />
          </ListItemButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ListItems;
