import * as React from "react";
import { useHttpRequest } from "../../Hooks/request-hook";
import { useForm } from "../../Hooks/form-hook";
import { AuthContext } from "../../Context/auth-context";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { CollectionsBookmarkRounded, TenMp } from "@mui/icons-material";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import pl from "moment/locale/pl";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const init = {
  status: "",
  clientid: "",
  note: "",
  address: "",
};

const AccountForm = (props) => {
  moment.locale();
  const auth = React.useContext(AuthContext);
  const [user, setUser] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [workers, setWorkers] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const { isLoading, error, request } = useHttpRequest();
  const [dateValue, setDateValue] = React.useState(null);
  const [client, setClient] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [inputClient, setInputClient] = React.useState("");
  const [optionClient, setOptionClient] = React.useState("");
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);

  const mapData = (data, clients) => {
    let date = data[0].date;
    setDateValue(date);
    setClients(clients);
    const client = clients.find((x) => {
      return x._id === data[0].clientid;
    });
    values.clientid = data[0].clientid;
    values.note = data[0].note;
    values.status = data[0].status;

    if (client.city && client.street && client.postalcode) {
      values.address =
        client.postalcode + " " + client.city + ", " + client.street;
    } else {
      if (client.city && client.street) {
        values.address = client.city + ", " + client.street;
      } else {
        if (client.city) {
          values.address = client.city;
        } else {
          values.address = "";
        }
      }
    }

    if (!client.pesel) {
      client.pesel = "";
    }

    setClient(client);
    setInputClient(client.name);
    setOptionClient(client.name);
  };

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request(
          `/meeting/get/${props.id}/${auth.admin}/${auth.id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        mapData(data.meetings, data.clients);
      } catch (err) {}
    };
    call();
  }, [request]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("clientid" in fieldValues) {
      temp.clientid = fieldValues.clientid ? "" : "Wybierz klienta";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    if (validate()) {
      try {
        const response = await request(
          `/meeting/update/${props.id}`,
          "PATCH",
          JSON.stringify({
            date: dateValue,
            note: values.note,
            status: values.status,
            clientid: values.clientid,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        window.location.reload();
        setMsg("Informacje o spotkaniu zostały zmienione!");
        setErrorMsg("");
      } catch (err) {
        setMsg("");
        setErrorMsg(
          "Nie można edytować spotkania, sprawdź poprawność wprowadzonych danych."
        );
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={moment.locale("pl")}
      >
        <Grid
          container
          spacing={2}
          direction={"row"}
          alignItems={"top"}
          justify={"space-evenly"}
        >
          <Grid xs={12} md={6} item>
            <FormControl fullWidth margin="normal">
              <DateTimePicker
                variant="inline"
                margin="normal"
                fullWidth
                label="Data spotkania"
                value={dateValue}
                onChange={(e) => {
                  setDateValue(e);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Status spotkania</InputLabel>
              <Select
                required
                labelId="status"
                id="status"
                value={values.status}
                label="Status spotkania"
                onChange={handleInputChange}
                name="status"
                fullWidth
              >
                <MenuItem value="W trakcie">W trakcie</MenuItem>
                <MenuItem value="Wypłacone">Wypłacony</MenuItem>
                <MenuItem value="Zakończone">Zakończone</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="normal"
              multiline
              fullWidth
              name="note"
              label="Notatka"
              id="outlined-multiline-flexible"
              value={values.note || ""}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal">
              <Autocomplete
                noOptionsText="Brak opcji"
                value={optionClient}
                onChange={(e, value) => {
                  if (value === null) {
                    value = "";
                  } else {
                    setValues({ ...values, clientid: value.id });
                    setOptionClient(value.label);
                  }
                }}
                inputValue={inputClient}
                onInputChange={(e, value) => {
                  if (value === null) {
                    value = "";
                  }
                  setInputClient(value);
                }}
                options={clients.map((x, index) => {
                  return { label: x.name + " #" + index, id: x._id };
                })}
                renderInput={(params) => (
                  <TextField {...params} label="Klient" />
                )}
              />
            </FormControl>
            {/* <FormControl fullWidth margin="normal">
              <InputLabel>Klient</InputLabel>
              <Select
                required
                labelId="clientid"
                id="clientid"
                value={values.clientid}
                label="Klient"
                onChange={handleInputChange}
                name="clientid"
                fullWidth
              >
                {clients &&
                  clients.map((x, index) => {
                    return (
                      <MenuItem key={index} value={x._id}>
                        {x.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl> */}
            <FormControl fullWidth margin="normal">
              <Accordion
                margin="normal"
                fullWidth
                style={{
                  boxShadow: "none",
                  borderBottom: "1px dashed rgba(0,0,0,0.3)",
                  paddingLeft: "0px",
                  borderRadius: "0px",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Pokaż dane klienta</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    margin="normal"
                    fullWidth
                    disabled
                    id="pesel"
                    label="PESEL"
                    name="pesel"
                    value={client.pesel || ""}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    fullWidth
                    id="idnr"
                    label="DOWÓD"
                    name="idnr"
                    value={client.idnr || ""}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    fullWidth
                    id="address"
                    label="ADRES"
                    name="address"
                    value={values.address || ""}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    fullWidth
                    id="pnumber"
                    label="TELEFON"
                    name="pnumber"
                    value={client.pnumber || ""}
                  />
                  <TextField
                    disabled
                    margin="normal"
                    fullWidth
                    id="email"
                    label="E-MAIL"
                    name="email"
                    value={client.email || ""}
                  />
                </AccordionDetails>
              </Accordion>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Zapisz zmiany
            </Button>
          </Grid>
        </Grid>
        {msg && (
          <Grid xs={12} item>
            <Alert
              variant="filled"
              severity="success"
              onClose={() => {
                setMsg("");
              }}
            >
              {msg}
            </Alert>
          </Grid>
        )}
        {errorMsg && (
          <Grid xs={12} item>
            <Alert
              variant="filled"
              severity="error"
              onClose={() => {
                setErrorMsg("");
              }}
            >
              {errorMsg}
            </Alert>
          </Grid>
        )}
      </LocalizationProvider>
    </Box>
  );
};

export default AccountForm;
