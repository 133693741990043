import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridActionsCellItem,
  plPL,
  GridRowParams,
  GridRowId,
} from "@mui/x-data-grid";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Autocomplete } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useHttpRequest } from "../../Hooks/request-hook";
import { useForm } from "../../Hooks/form-hook";
import { AuthContext } from "../../Context/auth-context";
import ColumnMenu from "./ColumnMenu";
import Alert from "@mui/material/Alert";
import moment from "moment";
import ClientMeetings from "./ClientMeetings";
import AddIcon from "@mui/icons-material/Add";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  ConstructionOutlined,
  FormatStrikethroughRounded,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { useSearchParams, useParams } from "react-router-dom";

const init = {
  client: "",
  worker: "",
  note: "",
};

const filter = {
  instType: "",
  inst: "",
  answer: "",
  name: "",
  date: "",
  status: "",
  datefrom: "",
  dateuntil: "",
};

export default function Meetings(props) {
  moment.locale();
  const auth = React.useContext(AuthContext);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const { isLoading, error, request } = useHttpRequest();
  const [user, setUser] = React.useState({});
  const [tableData, setTableData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [workers, setWorkers] = React.useState([]);
  const [banks, setBanks] = React.useState([]);
  const [inst, setInst] = React.useState([]);
  const [clients, setClients] = React.useState([]);
  const [meeting, setMeeting] = React.useState({});
  const [applications, setApplications] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [inputClient, setInputClient] = React.useState("");
  const [inputWorker, setInputWorker] = React.useState("");
  const [optionWorker, setOptionWorker] = React.useState("");
  const [optionClient, setOptionClient] = React.useState("");
  const [date, setDate] = React.useState(Date.now());
  const [params] = useSearchParams();
  const [checked, setChecked] = React.useState(false);
  const [allData, setAllData] = React.useState([]);
  const [dateFrom, setDateFrom] = React.useState(null);
  const [dateUntil, setDateUntil] = React.useState(null);
  let filteredapps = [];
  let navigate = useNavigate();
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);

  const mapData = (data, clients, workers, apps) => {
    const result = data.map((x) => {
      let date = moment(x.date).format("DD.MM.YYYY, HH:mm");
      const client = clients.find((a) => {
        return a._id === x.clientid;
      });
      let email = client.email.slice(0, 5) + "...";
      let pesel;
      if (client.pesel) {
        pesel = client.pesel.slice(0, 6) + "...";
      }
      let pnumber;
      if (client.pnumber) {
        pnumber = client.pnumber.toString().slice(0, 6) + "...";
      } else {
        pnumber = "";
      }

      let worker = workers.find((a) => {
        return x.worker === a._id;
      });

      return {
        id: x.id,
        date: date,
        _date: x.date,
        name: client.name,
        _clientid: client._id,
        pesel: pesel,
        _pesel: client.pesel,
        pnumber: pnumber,
        email: email,
        _emailFull: client.email,
        worker: worker.name,
        workerid: worker._id,
        status: x.status,
      };
    });
    //setLoaded(true);
    const filters = Object.fromEntries([...params]);
    filter.instType =
      filters.type || localStorage.getItem("minsttypefilter") || "";
    filter.category =
      filters.category || localStorage.getItem("mcategoryfilter") || "";
    filter.inst = filters.inst || localStorage.getItem("minstfilter") || "";
    filter.answer = localStorage.getItem("manswerfilter") || "";
    filter.name = localStorage.getItem("mnamefilter") || "";
    filter.date = localStorage.getItem("mdatefilter") || "";
    filter.status = localStorage.getItem("mstatusfilter") || "";
    filter.datefrom = localStorage.getItem("mdatefrom") || "";
    filter.dateuntil = localStorage.getItem("mdateuntil") || "";

    setDateFrom(filter.datefrom);
    setDateUntil(filter.dateuntil);
    setAllData(result);
    setTableData(result);
    filterApps(result, apps);
  };

  const mapAdd = (data) => {
    const result = data.map((x) => {
      let date = moment(x.date).format("DD.MM.YYYY, HH:mm");
      const client = clients.find((a) => {
        return a._id === x.clientid;
      });
      let email = client.email.slice(0, 5) + "...";
      let pesel;
      if (x.pesel) {
        pesel = x.pesel.slice(0, 6) + "...";
      }
      let pnumber;
      if (client.pnumber) {
        pnumber = client.pnumber.toString().slice(0, 6) + "...";
      } else {
        pnumber = "";
      }
      const worker = workers.find((a) => {
        return x.worker === a._id;
      });

      return {
        id: x.id,
        date: date,
        _date: x.date,
        name: client.name,
        _clientid: client._id,
        pesel: pesel,
        _pesel: client.pesel,
        pnumber: pnumber,
        email: email,
        _emailFull: client.email,
        worker: worker.name,
        status: "",
      };
    });
    let temp;
    temp = [...allData, result[0]];
    values.client = "";
    values.note = "";
    values.worker = "";
    setOptionWorker("");
    setOptionClient("");
    setInputClient("");
    setInputWorker("");
    setAllData(temp);
    setTableData(temp);
    filterApps(temp, applications);
  };

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request(
          `/meeting/all/${auth.admin}/${props.id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setApplications(data.applications);
        setClients(data.clients);
        setWorkers(data.users);
        setBanks(data.banks);
        setInst(data.inst);
        mapData(data.meetings, data.clients, data.users, data.applications);
      } catch (err) {}
    };
    call();
  }, [request]);

  const filterData = (result) => {
    const data = result.filter((x) => {
      const name = x.name.toLowerCase();
      const email = x._emailFull.toLowerCase();
      const date = new Date(x._date);
      const datenow = new Date();
      const dateuntil = new Date(filter.dateuntil);
      let pesel;

      if (!x._pesel) {
        pesel = " ";
      } else {
        pesel = x._pesel;
      }
      return (
        (name.includes(filter.name.toLowerCase()) ||
          pesel.includes(filter.name) ||
          x.worker.includes(filter.name) ||
          x.id.includes(filter.name) ||
          email.includes(filter.name.toLowerCase()) ||
          x.date.includes(filter.name)) &&
        (filter.status === "" ? true : x.status === filter.status) &&
        (filter.date === ""
          ? true
          : filter.date === ">"
          ? date - datenow > 0
          : filter.date === "<"
          ? date - datenow < 0
          : date.getUTCDate() === datenow.getUTCDate() &&
            date.getUTCMonth() === datenow.getUTCMonth() &&
            date.getUTCFullYear() === datenow.getUTCFullYear()) &&
        (filter.datefrom === "" ? true : x._date > filter.datefrom) &&
        (filter.dateuntil === ""
          ? true
          : x._date < filter.dateuntil ||
            (date.getUTCDate() === dateuntil.getUTCDate() &&
              date.getUTCMonth() === dateuntil.getUTCMonth() &&
              date.getUTCFullYear() === dateuntil.getUTCFullYear()))
      );
    });
    setFilteredData(data);
    setLoaded(true);
  };

  const filterDataCombined = (result) => {
    const data = result.filter((x) => {
      const date = new Date(x._date);
      const datenow = new Date();
      const dateuntil = new Date(filter.dateuntil);
      const name = x.name.toLowerCase();
      const worker = x.worker.toLowerCase();
      const email = x._emailFull.toLowerCase();
      let pesel;
      if (!x._pesel) {
        pesel = " ";
      } else {
        pesel = x._pesel;
      }
      let app;
      if (filteredapps.length > 0) {
        app = filteredapps.filter((y) => {
          return x.id === y.meetingid;
        });
        if (app.length) {
          return (
            (name.includes(filter.name.toLowerCase()) ||
              pesel.includes(filter.name) ||
              worker.includes(filter.name.toLowerCase()) ||
              email.includes(filter.name.toLowerCase()) ||
              x.id.includes(filter.name) ||
              x.date.includes(filter.name)) &&
            (filter.status === "" ? true : x.status === filter.status) &&
            (filter.date === ""
              ? true
              : filter.date === ">"
              ? date - datenow > 0
              : filter.date === "<"
              ? date - datenow < 0
              : date.getUTCDate() === datenow.getUTCDate() &&
                date.getUTCMonth() === datenow.getUTCMonth() &&
                date.getUTCFullYear() === datenow.getUTCFullYear()) &&
            (filter.datefrom === "" ? true : x._date > filter.datefrom) &&
            (filter.dateuntil === ""
              ? true
              : x._date < filter.dateuntil ||
                (date.getUTCDate() === dateuntil.getUTCDate() &&
                  date.getUTCMonth() === dateuntil.getUTCMonth() &&
                  date.getUTCFullYear() === dateuntil.getUTCFullYear()))
          );
        } else {
          return;
        }
      }
    });
    setFilteredData(data);
    setLoaded(true);
  };

  const filterApps = (result, apps) => {
    setLoaded(false);
    if (filter.inst || filter.instType || filter.answer) {
      filteredapps = apps.filter((x) => {
        return (
          x.inst.toLowerCase().includes(filter.inst.toLowerCase()) &&
          x.instType.includes(filter.instType) &&
          x.answer.includes(filter.answer)
        );
      });
      filterDataCombined(result);
    } else {
      filterData(result);
    }
  };

  const validateAdd = (fieldValues = values) => {
    let temp = { ...errors };
    if ("worker" in fieldValues) {
      if (auth.admin) {
        temp.worker = fieldValues.worker ? "" : "Wybierz opiekuna";
      } else {
        values.worker = auth.id;
        temp.worker = "";
      }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const columns = [
    { field: "id", headerName: "ID", width: 40 },
    {
      field: "date",
      headerName: "Data spotkania",
      type: "date",
      flex: 1,
      minWidth: 140,
    },
    { field: "_date", headerName: "_DATA", type: "date", flex: 1 },
    { field: "name", headerName: "Imię i nazwisko", flex: 1, minWidth: 170 },
    { field: "pesel", headerName: "PESEL", flex: 1, minWidth: 60 },
    { field: "_pesel", headerName: "_PESEL", flex: 1 },
    { field: "_clientid", headerName: "_CLIENTID" },
    { field: "email", headerName: "E-mail", flex: 1, minWidth: 130 },
    {
      field: "_emailFull",
      headerName: "_emailFull",
      type: "email",
      width: 0,
    },
    { field: "worker", headerName: "Opiekun", flex: 1, minWidth: 160 },
    { field: "workerid", headerName: "Opiekunid" },
    { field: "status", headerName: "Status", flex: 1, minWidth: 140 },
    {
      field: "actions",
      headerName: "Akcja",
      flex: 1,
      minWidth: 60,
      type: "actions",
      getActions: (GridRowParams) => [
        <GridActionsCellItem
          label="Zobacz szczegóły"
          onClick={() => {
            navigate(
              `/meetings/${GridRowParams.getValue(GridRowParams.id, "id")}/${
                props.id
              }`
            );
          }}
          showInMenu
        />,
        // <GridActionsCellItem
        //   label={
        //     GridRowParams.getValue(GridRowParams.id, "status") === "Wykonane"
        //       ? "Oznacz jako oczekujące"
        //       : "Oznacz jako wykonane"
        //   }
        //   onClick={async () => {
        //     setMsg("");
        //     setErrorMsg("");
        //     try {
        //       const response = await request(
        //         `/meeting/status/${GridRowParams.getValue(
        //           GridRowParams.id,
        //           "id"
        //         )}`,
        //         "PATCH",
        //         null,
        //         {
        //           "Content-Type": "application/json",
        //           Authorization: "Bearer " + auth.token,
        //         }
        //       );
        //       const index = tableData.findIndex((x) => {
        //         return x.id === GridRowParams.getValue(GridRowParams.id, "id");
        //       });
        //       if (tableData[index].status === "Oczekujące") {
        //         tableData[index].status = "Wykonane";
        //       } else {
        //         tableData[index].status = "Oczekujące";
        //       }
        //       setMsg(`Status spotkania został zmieniony.`);
        //       setErrorMsg("");
        //     } catch (err) {
        //       setMsg("");
        //       setErrorMsg("Wystąpił błąd, spróbuj ponownie później.");
        //     }
        //   }}
        //   showInMenu
        // />,
        <GridActionsCellItem
          label="Usuń"
          onClick={() => {
            setMeeting({ id: GridRowParams.getValue(GridRowParams.id, "id") });
            handleClickOpenDelete();
          }}
          disabled={!auth.admin}
          showInMenu
        />,
      ],
    },
  ];

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClickCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    try {
      const response = await request(
        `/meeting/delete/${meeting.id}`,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      handleClickCloseDelete();
      window.location.reload();
      setMsg("Spotkanie zostało usunięte.");
      setErrorMsg("");
    } catch (err) {
      console.log(err);
      handleClickCloseDelete();
      setMsg("");
      setErrorMsg("Nie można usunąć spotkania, spróbuj ponownie później.");
    }
  };

  const handleSubmitAdd = async (event) => {
    event.preventDefault();
    setErrors({});
    if (validateAdd()) {
      try {
        const response = await request(
          "/meeting/new",
          "POST",
          JSON.stringify({
            date: date,
            worker: values.worker,
            clientid: props.id,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        const newmeeting = [response.meeting];
        mapAdd(newmeeting);
        setMsg("Spotkanie zostało dodane.");
        setErrorMsg("");
        handleCloseAdd();
      } catch (err) {
        setMsg("");
        setErrorMsg(
          "Nie można dodać nowego spotkania, sprawdź poprawność wprowadzonych danych."
        );
        handleCloseAdd();
      }
      try {
        const response = await request(
          "/clients/activity/update",
          "PATCH",
          JSON.stringify({ id: props.id }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        //
      }
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          "& .active": {
            color: "#4ef300",
            fontWeight: "bold",
          },
          "& .blocked": {
            color: "#e40000",
            fontWeight: "bold",
          },
        }}
      >
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid item xs={12} md={8}>
            <h2>Historia spotkań</h2>
          </Grid>
          <Grid item xs={12} md={4} justifyContent="flex-end">
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="big"
              onClick={handleClickOpenAdd}
              startIcon={<AddIcon />}
            >
              Dodaj
            </Button>
          </Grid>
        </Grid>
        {loaded && (
          <React.Fragment>
            {/* <Grid container spacing={2} columns={12}> */}
            {/* <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Data</InputLabel>
                  <Select
                    labelId="datefilter"
                    id="datefilter"
                    label="Data"
                    value={filter.date}
                    onChange={(e) => {
                      localStorage.setItem("mdatefilter", e.target.value);
                      filter.date = e.target.value;
                      filterApps(tableData, applications);
                    }}
                    name="datefilter"
                  >
                    <MenuItem value="">Brak</MenuItem>
                    <MenuItem value=">">Nadchodzące</MenuItem>
                    <MenuItem value="===">Dziś</MenuItem>
                    <MenuItem value="<">Przeszłe</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
            {/* <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Status</InputLabel>
                  <Select
                    labelId="statusfilter"
                    id="statusfilter"
                    label="Status"
                    name="statusfilter"
                    value={filter.status}
                    onChange={(e) => {
                      localStorage.setItem("mstatusfilter", e.target.value);
                      filter.status = e.target.value;
                      filterApps(tableData, applications);
                    }}
                  >
                    <MenuItem value="">Brak</MenuItem>
                    <MenuItem value="W trakcie">W trakcie</MenuItem>
                    <MenuItem value="Wypłacone">Wypłacone</MenuItem>
                    <MenuItem value="Zakończone">Zakończone</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
            {/* <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Typ instytucji</InputLabel>
                  <Select
                    labelId="insttypefilter"
                    id="insttypefilter"
                    label="Typ instytucji"
                    name="insttypefilter"
                    value={filter.instType}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        filter.inst = "";
                        localStorage.setItem("minsttypefilter", "");
                      }
                      localStorage.setItem("minsttypefilter", e.target.value);
                      filter.instType = e.target.value;
                      filterApps(tableData, applications);
                    }}
                  >
                    <MenuItem value="">Brak</MenuItem>
                    <MenuItem value="Bank">Bank</MenuItem>
                    <MenuItem value="Instytucja pozabankowa">
                      Intytucja pozabankowa
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
            {/* <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <Autocomplete
                    noOptionsText="Brak opcji"
                    value={filter.inst}
                    onChange={(e, value) => {
                      if (value === null) {
                        value = "";
                        localStorage.setItem("minstfilter", value);
                      }
                      localStorage.setItem("minstfilter", value);
                      filter.inst = value;
                      filterApps(tableData, applications);
                    }}
                    inputValue={filter.inst}
                    onInputChange={(e, value) => {
                      if (value === null) {
                        value = "";
                        localStorage.setItem("minstfilter", value);
                      }
                      localStorage.setItem("minstfilter", value);
                      filter.inst = value;
                      filterApps(tableData, applications);
                    }}
                    options={
                      filter.instType === "Bank"
                        ? banks.map((bank, index) => {
                            return bank.name;
                          })
                        : filter.instType === "Instytucja pozabankowa"
                        ? inst.map((inst, index) => {
                            return inst.name;
                          })
                        : []
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Instytucja" />
                    )}
                  />
                </FormControl>
              </Grid> */}
            {/* <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Odpowiedź</InputLabel>
                  <Select
                    labelId="answer"
                    id="answer"
                    label="Odpowiedź"
                    value={filter.answer}
                    onChange={(e) => {
                      localStorage.setItem("manswerfilter", e.target.value);
                      filter.answer = e.target.value;
                      filterApps(tableData, applications);
                    }}
                    name="answer"
                  >
                    <MenuItem value="">Brak</MenuItem>
                    <MenuItem value="Wysłano">Wysłano</MenuItem>
                    <MenuItem value="Odmowa">Odmowa</MenuItem>
                    <MenuItem value="Oferta">Oferta</MenuItem>
                    <MenuItem value="Wypłacony">Wypłacony</MenuItem>
                    <MenuItem value="Nie wysłano">Nie wysłano</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="date"
                    variant="standard"
                    value={dateFrom}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      filter.datefrom = e.target.value;
                      setDateFrom(e.target.value);
                      localStorage.setItem("mdatefrom", filter.datefrom);
                      filterApps(tableData, applications);
                    }}
                    label="Data spotkania od:"
                  />
                </FormControl>
              </Grid> */}
            {/* <Grid xs={6} md={3} item>
                <FormControl fullWidth margin="normal">
                  <TextField
                    type="date"
                    variant="standard"
                    value={dateUntil}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      filter.dateuntil = e.target.value;
                      setDateUntil(e.target.value);
                      localStorage.setItem("mdateuntil", filter.dateuntil);
                      filterApps(tableData, applications);
                    }}
                    label="Data spotkania do:"
                  />
                </FormControl>
              </Grid> */}
            {/* <Grid xs={6} md={3} item>
                <TextField
                  fullWidth
                  margin="normal"
                  id="namefilter"
                  label="Szukaj..."
                  name="namefilter"
                  type="namefilter"
                  variant="standard"
                  value={filter.name}
                  onChange={(e) => {
                    localStorage.setItem("mnamefilter", e.target.value);
                    filter.name = e.target.value;
                    filterApps(tableData, applications);
                  }}
                />
              </Grid> */}
            {/* </Grid> */}
            <DataGrid
              autoHeight
              autoWidth
              rows={filteredData}
              columns={columns}
              onRowClick={(data) => {
                navigate(`/meetings/${data.row.id}/${props.id}`);
              }}
              disableSelectionOnClick
              // getCellClassName={(params) => {
              //   if (params.field === "status") {
              //     return params.value === "Oczekujące" ? "active" : "blocked";
              //   }
              // }}
              localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
              columnVisibilityModel={{
                workerid: false,
                _clientid: false,
                _emailFull: false,
                _pesel: false,
                _date: false,
              }}
              components={{
                ColumnMenu: ColumnMenu,
              }}
            />
          </React.Fragment>
        )}
        <Dialog open={openDelete} onClose={handleClickCloseDelete}>
          <DialogTitle>{"Czy napewno chcesz usunąć spotkanie?"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Wszystkie dane zostaną bezpowrotnie utracone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickCloseDelete}>Nie</Button>
            <Button onClick={handleDelete} autoFocus>
              Tak
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openAdd} onClose={handleCloseAdd}>
          <DialogTitle>Dodaj nowe spotkanie</DialogTitle>
          <DialogContent>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={moment.locale("pl")}
            >
              <FormControl margin="normal" fullWidth>
                <DateTimePicker
                  variant="inline"
                  margin="normal"
                  fullWidth
                  label="Data spotkania"
                  value={date}
                  onChange={(e) => {
                    setDate(e);
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </FormControl>
              {/* <FormControl fullWidth margin="normal" error={errors.client}>
                <InputLabel>Klient</InputLabel>
                <Select
                  required
                  labelId="client"
                  id="client"
                  value={values.client}
                  label="Klient"
                  onChange={handleInputChange}
                  name="client"
                  fullWidth
                >
                  {clients.map((x, index) => {
                    return (
                      <MenuItem key={index} value={x._id}>
                        {x.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}
              {auth.admin && (
                // <FormControl fullWidth margin="normal" error={errors.worker}>
                //   <InputLabel>Opiekun</InputLabel>
                //   <Select
                //     required
                //     labelId="worker"
                //     id="worker"
                //     value={values.worker}
                //     label="Opiekun"
                //     onChange={handleInputChange}
                //     name="worker"
                //     fullWidth
                //   >
                //     {workers.map((worker, index) => {
                //       return (
                //         <MenuItem key={index} value={worker._id}>
                //           {worker.name}
                //         </MenuItem>
                //       );
                //     })}
                //   </Select>
                // </FormControl>
                <FormControl fullWidth margin="normal">
                  <Autocomplete
                    noOptionsText="Brak opcji"
                    value={optionWorker}
                    onChange={(e, value) => {
                      if (value === null) {
                        value = { id: "" };
                      }
                      setValues({ ...values, worker: value.id });
                      setOptionWorker(value.label);
                    }}
                    inputValue={inputWorker}
                    onInputChange={(e, value) => {
                      if (value === null) {
                        value = "";
                      }
                      setInputWorker(value);
                    }}
                    options={workers.map((worker, index) => {
                      return {
                        label: worker.name + " #" + index,
                        id: worker._id,
                      };
                    })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Opiekun"
                        error={errors.worker}
                        helperText={errors.worker}
                      />
                    )}
                  />
                </FormControl>
              )}
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAdd}>Anuluj</Button>
            <Button onClick={handleSubmitAdd}>Dodaj</Button>
          </DialogActions>
        </Dialog>
        {errorMsg && (
          <Alert
            variant="filled"
            severity="error"
            onClose={() => {
              setErrorMsg("");
            }}
          >
            {errorMsg}
          </Alert>
        )}
        {msg && (
          <Alert
            variant="filled"
            severity="success"
            onClose={() => {
              setMsg("");
            }}
          >
            {msg}
          </Alert>
        )}
      </Box>
    </div>
  );
}
