import React from "react";
import MeetingWindow from "../../Components/MainWindows/MeetingWindow";
import { useSearchParams, useParams } from "react-router-dom";

const Meeting = () => {
  const { id, client } = useParams();
  document.title = "Spotkanie - AJ Finanse";
  return (
    <React.Fragment>
      <MeetingWindow name="Spotkanie" id={id} client={client}/>
    </React.Fragment>
  );
};

export default Meeting;
