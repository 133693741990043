import * as React from "react";
import { useHttpRequest } from "../../Hooks/request-hook";
import { useForm } from "../../Hooks/form-hook";
import { AuthContext } from "../../Context/auth-context";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { TenMp } from "@mui/icons-material";

const init = {
  name: "",
  pnumber: "",
  email: "",
  password1: "",
  password2: "",
};

const AccountForm = () => {
  const auth = React.useContext(AuthContext);
  const [user, setUser] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const { isLoading, error, request } = useHttpRequest();
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);
  let type;
  let passChange = false;

  const mapData = (data) => {
    let pnumber;
    if (!data.pnumber) {
      pnumber = "";
    } else {
      pnumber = data.pnumber;
    }

    values.name = data.name;
    values.email = data.email;
    values.pnumber = pnumber;
    setUser({
      ...values,
    });
  };

  React.useEffect(() => {
    if (auth.admin === true) {
      type = "admin";
    } else {
      type = "user";
    }
    const call = async () => {
      try {
        const data = await request(
          `/account/get/${type}/${auth.id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        mapData(data.user);
      } catch (err) {}
    };
    call();
  }, [request]);

  const validate = (fieldValues = values) => {
    setMsg("");
    setErrorMsg("");
    passChange = false;
    let temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "Podaj imię i nazwisko";
    }
    if ("email" in fieldValues) {
      temp.email = /^\S+@\S+\.\S+$/.test(fieldValues.email)
        ? ""
        : "Podaj poprawny adres email";
    }
    if ("pnumber" in fieldValues) {
      if (fieldValues.pnumber !== "") {
        temp.pnumber =
          fieldValues.pnumber.toString().length >= 9
            ? ""
            : "Podaj poprawny numer telefonu";
      }
    }
    if ("password1" in fieldValues) {
      if (fieldValues.password1 !== "") {
        passChange = true;
        temp.password1 =
          fieldValues.password1.length >= 6 ? "" : "Hasło jest zbyt krótkie";
      } else {
        passChange = false;
      }
    }
    if ("password2" in fieldValues) {
      if (fieldValues.password2 !== "" || passChange) {
        temp.password2 =
          fieldValues.password1 === fieldValues.password2
            ? ""
            : "Hasła nie są podobne";
      }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    if (auth.admin === true) {
      type = "admin";
    } else {
      type = "user";
    }

    if (validate()) {
      if (passChange) {
        try {
          const response = await request(
            `/account/updatepass/${type}/${auth.id}`,
            "PATCH",
            JSON.stringify({
              name: values.name,
              email: values.email,
              pnumber: values.pnumber,
              password: values.password1,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
          setErrorMsg("");
          setMsg("Dane zostały zmienione!");
          values.password1 = "";
          values.password2 = "";
        } catch (err) {
          setMsg("");
          setErrorMsg(
            "Sprawdź poprawność wprowadzanych danych i spróbuj ponownie"
          );
        }
      }

      if (!passChange) {
        try {
          const response = await request(
            `/account/update/${type}/${auth.id}`,
            "PATCH",
            JSON.stringify({
              name: values.name,
              email: values.email,
              pnumber: values.pnumber,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
          setErrorMsg("");
          setMsg("Dane zostały zmienione!");
        } catch (err) {
          setMsg("");
          setErrorMsg(
            "Sprawdź poprawność wprowadzanych danych i spróbuj ponownie"
          );
        }
      }
    } else {
      setErrorMsg("Sprawdź poprawność wprowadzanych danych i spróbuj ponownie");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="name"
        label="Imię i nazwisko"
        name="name"
        value={values.name}
        onChange={handleInputChange}
        error={errors.name}
        helperText={errors.name}
        autoFocus
      />
      <TextField
        margin="normal"
        fullWidth
        id="pnumber"
        label="Numer telefonu"
        name="pnumber"
        value={values.pnumber}
        onChange={handleInputChange}
        error={errors.pnumber}
        helperText={errors.pnumber}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        value={values.email}
        onChange={handleInputChange}
        error={errors.email}
        helperText={errors.email}
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password1"
        label="Nowe hasło"
        type="password"
        id="password1"
        value={values.password1}
        onChange={handleInputChange}
        error={errors.password1}
        helperText={errors.password1}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password2"
        label="Potwierdź hasło"
        type="password"
        id="password2"
        value={values.password2}
        onChange={handleInputChange}
        error={errors.password2}
        helperText={errors.password2}
      />
      {msg && (
        <Alert
          variant="filled"
          severity="success"
          onClose={() => {
            setMsg("");
          }}
        >
          {msg}
        </Alert>
      )}
      {errorMsg && (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setErrorMsg("");
          }}
        >
          {errorMsg}
        </Alert>
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={handleSubmit}
      >
        Zapisz zmiany
      </Button>
    </Box>
  );
};

export default AccountForm;
