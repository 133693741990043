import React from "react";
import ClientWindow from "../../Components/MainWindows/ClientWindow";
import { useSearchParams, useParams } from "react-router-dom";

const Client = () => {
  const { id } = useParams();
  document.title = "Klient - AJ Finanse";
  return (
    <React.Fragment>
      <ClientWindow name="Klient" id={id}/>
    </React.Fragment>
  );
};

export default Client;
