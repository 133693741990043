import React from "react";
import CallendarWindow from "../../Components/MainWindows/CallendarWindow";

const Callendar = () => {
  document.title = "Kalendarz - AJ Finanse"
  return (
    <React.Fragment>
      <CallendarWindow name="Kalendarz"/>
    </React.Fragment>
  );
};

export default Callendar;
