import * as React from "react";
import { useHttpRequest } from "../../Hooks/request-hook";
import { useForm } from "../../Hooks/form-hook";
import { AuthContext } from "../../Context/auth-context";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import { TenMp } from "@mui/icons-material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Autocomplete } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import pl from "moment/locale/pl";

const init = {
  name: "",
  pnumber: "",
  email: "",
  pesel: "",
  idnr: "",
  city: "",
  postalcode: "",
  street: "",
  worker: "",
  category: "",
  note: "",
  income: "",
  incomesrc: "",
  source: "",
  undocumented: "",
  status: "",
};

const AccountForm = (props) => {
  moment.locale();
  const auth = React.useContext(AuthContext);
  const [user, setUser] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [workers, setWorkers] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const { isLoading, error, request } = useHttpRequest();
  const [dateValue, setDateValue] = React.useState(null);
  const [optionWorker, setOptionWorker] = React.useState("");
  const [inputWorker, setInputWorker] = React.useState("");
  const [birthday, setBirthday] = React.useState(null);
  const [inputCategory, setInputCategory] = React.useState("");
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request(`/clients/${props.id}`, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        mapData(data.clients, data.workers);
        setWorkers(data.workers);
        setCategories(data.categories);
      } catch (err) {}
    };
    call();
  }, [request]);

  const mapData = (data, workers) => {
    let date = data.date;
    setDateValue(date);
    let birthday = data.birthday;
    setBirthday(birthday);
    let city;
    if (!data.city) {
      city = "";
    } else {
      city = data.city;
    }

    let postalcode;
    if (!data.postalcode) {
      postalcode = "";
    } else {
      postalcode = data.postalcode;
    }

    let status;
    if (!data.status) {
      status = "";
    } else {
      status = data.status;
    }

    let pesel;
    if (!data.pesel) {
      pesel = "";
    } else {
      pesel = data.pesel;
    }

    let street;
    if (!data.street) {
      street = "";
    } else {
      street = data.street;
    }

    let idnr;
    if (!data.idnr) {
      idnr = "";
    } else {
      idnr = data.idnr;
    }

    let note;
    if (!data.note) {
      note = "";
    } else {
      note = data.note;
    }

    let income;
    if (!data.income) {
      income = "";
    } else {
      income = data.income;
    }

    let incomesrc;
    if (!data.incomesrc) {
      incomesrc = "";
    } else {
      incomesrc = data.incomesrc;
    }

    let source;
    if (!data.source) {
      source = "";
    } else {
      source = data.source;
    }

    let undocumented;
    if (!data.undocumented) {
      undocumented = "";
    } else {
      undocumented = data.undocumented;
    }

    let worker;
    workers.map((y) => {
      if (y.id === data.worker) {
        worker = y.name;
      }
    });
    if (!worker) {
      worker = "";
    }

    let pnumber;
    workers.map((y) => {
      if (y.pnumber === data.pnumber) {
        pnumber = y.pnumber;
      }
    });
    if (!pnumber) {
      pnumber = "";
    }
    setInputWorker(worker);
    setOptionWorker(worker);
    setInputCategory(data.category);
    values.name = data.name;
    values.email = data.email;
    values.pnumber = data.pnumber;
    values.pesel = pesel;
    values.category = data.category;
    values.city = city;
    values.postalcode = postalcode;
    values.street = street;
    values.idnr = idnr;
    values.worker = data.worker;
    values.note = note;
    values.income = income;
    values.incomesrc = incomesrc;
    values.source = source;
    values.undocumented = undocumented;
    values.status = status;
    setUser({
      ...values,
    });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (values.email) {
        temp.email = /^\S+@\S+\.\S+$/.test(fieldValues.email)
          ? ""
          : "Podaj poprawny adres email";
      }
    }
    // if ("pnumber" in fieldValues) {
    //   temp.pnumber =
    //     fieldValues.pnumber.toString().length >= 9
    //       ? ""
    //       : "Podaj poprawny numer telefonu";
    // }
    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : "Podaj poprawne imię i nazwisko";
    }
    if ("worker" in fieldValues) {
      temp.worker = fieldValues.worker ? "" : "Wybierz opiekuna";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});

    if (validate()) {
      try {
        const response = await request(
          `/clients/update/${props.id}`,
          "PATCH",
          JSON.stringify({
            name: values.name,
            email: values.email,
            pesel: values.pesel,
            pnumber: values.pnumber,
            category: values.category,
            city: values.city,
            idnr: values.idnr,
            postalcode: values.postalcode,
            street: values.street,
            worker: values.worker,
            date: dateValue,
            income: values.income,
            incomesrc: values.incomesrc,
            birthday: birthday,
            source: values.source,
            undocumented: values.undocumented,
            note: values.note,
            status: values.status,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setMsg("Dane zostały zmienione!");
        setErrorMsg("");
      } catch (err) {
        setMsg("");
        setErrorMsg(
          "Nie można edytować profilu klienta, sprawdź poprawność wprowadzonych danych."
        );
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={moment.locale("pl")}
      >
        <Grid
          container
          spacing={2}
          direction={"row"}
          alignItems={"center"}
          justify={"space-evenly"}
        >
          <Grid item xs={12} md={4}>
            <TextField
              margin="normal"
              fullWidth
              id="name"
              label="Imię i nazwisko"
              name="name"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              margin="normal"
              fullWidth
              id="pnumber"
              label="Numer telefonu"
              name="pnumber"
              value={values.pnumber}
              onChange={handleInputChange}
              error={errors.pnumber}
              helperText={errors.pnumber}
            />
          </Grid>
          <Grid item xs={6} md={4}>
          <FormControl fullWidth margin="normal">
                      <InputLabel>Status</InputLabel>
                      <Select
                        labelId="status"
                        id="status"
                        value={values.status}
                        label="Status"
                        onChange={handleInputChange}
                        name="status"
                        fullWidth
                      >
                        <MenuItem value="W toku">W toku</MenuItem>
                        <MenuItem value="Zakończony">Zakończony</MenuItem>
                      </Select>
                    </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Accordion
              fullWidth
              style={{
                boxShadow: "none",
                borderBottom: "1px dashed rgba(0,0,0,0.3)",
                paddingLeft: "0px",
                borderRadius: "0px",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Pokaż więcej danych klienta</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={2}
                  direction={"row"}
                  alignItems={"center"}
                  justify={"space-evenly"}
                >
                  <Grid item xs={6} md={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="pesel"
                      label="PESEL"
                      id="pesel"
                      value={values.pesel}
                      onChange={handleInputChange}
                      error={errors.pesel}
                      helperText={errors.pesel}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="idnr"
                      label="Nr dowodu"
                      id="idnr"
                      value={values.idnr}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                      error={errors.email}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid xs={6} md={3} item>
                    <FormControl margin="normal" fullWidth>
                      <DatePicker
                        variant="inline"
                        margin="normal"
                        fullWidth
                        label="Data urodzenia"
                        value={birthday}
                        onChange={(e) => {
                          setBirthday(e);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="postalcode"
                      label="Kod pocztowy"
                      id="postalcode"
                      value={values.postalcode}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="city"
                      label="Miejscowość"
                      id="city"
                      value={values.city}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="street"
                      label="Ulica i numer"
                      id="street"
                      value={values.street}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="income"
                      label="Dochód"
                      id="income"
                      value={values.income}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Źródło dochodu</InputLabel>
                      <Select
                        labelId="incomesrc"
                        id="incomesrc"
                        value={values.incomesrc}
                        label="Źródło dochodu"
                        onChange={handleInputChange}
                        name="incomesrc"
                        fullWidth
                      >
                        <MenuItem value="Umowa o pracę">Umowa o pracę</MenuItem>
                        <MenuItem value="Umowa zlecenie">
                          Umowa zlecenie
                        </MenuItem>
                        <MenuItem value="Działalność gospodarcza">
                          Działalność gospodarcza
                        </MenuItem>
                        <MenuItem value="Emerytura">Emerytura</MenuItem>
                        <MenuItem value="Renta">Renta</MenuItem>
                        <MenuItem value="Renta rodzinna">
                          Renta rodzinna
                        </MenuItem>
                        <MenuItem value="Inne">Inne</MenuItem>
                        <MenuItem value="Brak">Brak</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="source"
                      label="Źródło pozyskania"
                      id="source"
                      value={values.source}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="undocumented"
                      label="Dochód nieudokumentowany"
                      id="undocumented"
                      value={values.undocumented}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  {auth.admin ? (
                    <React.Fragment>
                      <Grid xs={6} md={4} item>
                        <DatePicker
                          variant="inline"
                          margin="normal"
                          label="Data rejestracji"
                          value={dateValue}
                          fullWidth
                          disabled
                          onChange={(e) => {
                            setDateValue(e);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        {/* <FormControl fullWidth margin="normal">
                  <InputLabel>Kategoria</InputLabel>
                  <Select
                    required
                    labelId="category"
                    id="category"
                    value={values.category}
                    label="Kategoria"
                    onChange={handleInputChange}
                    name="category"
                    fullWidth
                  >
                    {categories.map((category, index) => {
                      return (
                        <MenuItem key={index} value={category.name}>
                          {category.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                        <FormControl fullWidth margin="normal">
                          <Autocomplete
                            noOptionsText="Brak opcji"
                            value={values.category}
                            onChange={(e, value) => {
                              if (value === null) {
                                value = "";
                              }
                              setValues({ ...values, category: value });
                            }}
                            inputValue={inputCategory}
                            onInputChange={(e, value) => {
                              if (value === null) {
                                value = "";
                              }
                              setInputCategory(value);
                            }}
                            options={categories.map((category) => {
                              return category.name;
                            })}
                            renderInput={(params) => (
                              <TextField {...params} label="Kategoria" />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        {/* <FormControl fullWidth margin="normal">
                  <InputLabel>Opiekun</InputLabel>
                  <Select
                    required
                    labelId="worker"
                    id="worker"
                    value={values.worker}
                    label="Opiekun"
                    onChange={handleInputChange}
                    name="worker"
                    fullWidth
                  >
                    {workers.map((worker, index) => {
                      return (
                        <MenuItem key={index} value={worker._id}>
                          {worker.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                        <FormControl fullWidth margin="normal">
                          <Autocomplete
                            noOptionsText="Brak opcji"
                            value={optionWorker}
                            onChange={(e, value) => {
                              if (value === null) {
                                value = { id: "" };
                              }
                              setValues({ ...values, worker: value.id });
                              setOptionWorker(value.label);
                            }}
                            inputValue={inputWorker}
                            onInputChange={(e, value) => {
                              if (value === null) {
                                value = "";
                              }
                              setInputWorker(value);
                            }}
                            options={workers.map((worker, index) => {
                              return { label: worker.name, id: worker._id };
                            })}
                            renderInput={(params) => (
                              <TextField {...params} label="Opiekun" />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid xs={6} item>
                        <DatePicker
                          variant="inline"
                          margin="normal"
                          label="Data rejestracji"
                          value={dateValue}
                          fullWidth
                          disabled
                          onChange={(e) => {
                            setDateValue(e);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {/* <FormControl fullWidth margin="normal">
                  <InputLabel>Kategoria</InputLabel>
                  <Select
                    required
                    labelId="category"
                    id="category"
                    value={values.category}
                    label="Kategoria"
                    onChange={handleInputChange}
                    name="category"
                    fullWidth
                  >
                    {categories.map((category, index) => {
                      return (
                        <MenuItem key={index} value={category.name}>
                          {category.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                        <FormControl fullWidth margin="normal">
                          <Autocomplete
                            noOptionsText="Brak opcji"
                            value={values.category}
                            onChange={(e, value) => {
                              if (value === null) {
                                value = "";
                              }
                              setValues({ ...values, category: value });
                            }}
                            inputValue={inputCategory}
                            onInputChange={(e, value) => {
                              if (value === null) {
                                value = "";
                              }
                              setInputCategory(values);
                            }}
                            options={categories.map((category) => {
                              return category.name;
                            })}
                            renderInput={(params) => (
                              <TextField {...params} label="Kategoria" />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </React.Fragment>
                  )}
                  <Grid xs={12} item>
                    <TextField
                      margin="normal"
                      multiline
                      fullWidth
                      name="note"
                      label="Notatka"
                      id="outlined-multiline-flexible"
                      value={values.note || ""}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {msg && (
            <Grid xs={12} item>
              <Alert
                variant="filled"
                severity="success"
                onClose={() => {
                  setMsg("");
                }}
              >
                {msg}
              </Alert>
            </Grid>
          )}
          {errorMsg && (
            <Grid xs={12} item>
              <Alert
                variant="filled"
                severity="error"
                onClose={() => {
                  setErrorMsg("");
                }}
              >
                {errorMsg}
              </Alert>
            </Grid>
          )}
          <Grid xs={12} item>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Zapisz zmiany
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Box>
  );
};

export default AccountForm;
