import React from "react";
import AccountWindow from "../../Components/MainWindows/AccountWindow";

const Account = () => {
  document.title = "Moje konto - AJ Finanse"
  return (
    <React.Fragment>
      <AccountWindow name="Moje konto"/>
    </React.Fragment>
  );
};

export default Account;
