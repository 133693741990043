import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridActionsCellItem,
  plPL,
  GridRowParams,
  GridRowId,
} from "@mui/x-data-grid";
import { useHttpRequest } from "../../Hooks/request-hook";
import { AuthContext } from "../../Context/auth-context";
import ColumnMenu from "./ColumnMenu";
import { CollectionsBookmarkOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import moment from "moment";

export default function AccountClients() {
  const auth = React.useContext(AuthContext);
  const { isLoading, error, request } = useHttpRequest();
  const [tableData, setTableData] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  let navigate = useNavigate();
  moment.locale();

  const mapData = (data, applications, meetings) => {
    let iteration = 0;
    const result = data.map((x) => {
      iteration = iteration + 1;
      let date = moment(x.date).format("DD.MM.YYYY");
      let pesel;
      if (x.pesel) {
        pesel = x.pesel.slice(0, 6) + "...";
      }

      const countapps = applications.filter((y) => {
        return y.clientid === x._id;
      }).length;

      const countmeetings = meetings.filter((y) => {
        return y.clientid === x._id;
      }).length;

      return {
        id: iteration,
        _id: x._id,
        date: date,
        name: x.name,
        pesel: pesel,
        meetings: countmeetings,
        applications: countapps,
      };
    });

    setLoaded(true);
    setTableData(result);
  };

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request(`/account/clients/${auth.id}`, "GET", null, {
          Authorization: "Bearer " + auth.token,
        });
        mapData(data.clients, data.applications, data.meetings);
      } catch (err) {
        console.log(err);
      }
    };
    call();
  }, [request]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 40,
      renderCell: (cell) => {
        return (
          <Link
            to={`/clients/${cell.row._id}`}
            style={{ color: "#000" }}
            onClick={(event) => {
              event.ignore = true;
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    { field: "_id", headerName: "_ID", width: 0 },
    {
      field: "date",
      headerName: "Data dodania",
      type: "date",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "name",
      headerName: "Imię i nazwisko",
      flex: 1,
      minWidth: 260,
      renderCell: (cell) => {
        return (
          <Link to={`/clients/${cell.row._id}`} style={{ color: "#000" }}>
            {cell.value}
          </Link>
        );
      },
    },
    {
      field: "pesel",
      headerName: "PESEL",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "meetings",
      headerName: "Odbytych spotkań",
      flex: 1,
      minWidth: 160,
      type: "number",
      // renderCell: (cell) => {
      //   return (
      //     <Link
      //       to={`/meetings?client=${cell.row.name}`}
      //       style={{ color: "#000" }}
      //       onClick={(event) => {
      //         event.ignore = true;
      //       }}
      //     >
      //       {cell.value}
      //     </Link>
      //   );
      // },
    },
    {
      field: "applications",
      headerName: "Złożonych wniosków",
      flex: 1,
      minWidth: 160,
      type: "number",
      // renderCell: (cell) => {
      //   return (
      //     <Link
      //       to="/meetings"
      //       style={{ color: "#000" }}
      //       onClick={(event) => {
      //         event.ignore = true;
      //       }}
      //     >
      //       {cell.value}
      //     </Link>
      //   );
      // },
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <h3>Przypisani klienci</h3>
      {loaded && (
        <DataGrid
          autoHeight
          autoWidth
          rows={tableData}
          columns={columns}
          pageSize={6}
          rowsPerPageOptions={[6]}
          disableSelectionOnClick
          onRowClick={(data, event) => {
            if (!event.ignore) {
              navigate(`/clients/${data.row._id}`);
            }
          }}
          localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
          columnVisibilityModel={{
            _id: false,
          }}
          components={{
            ColumnMenu: ColumnMenu,
          }}
        />
      )}
    </div>
  );
}
