import "./LoginWindow.css";
import React, { useCallback, useReducer, useContext } from "react";
import { Link } from "react-router-dom";

import { validate, VALIDATOR_REQUIRE, VALIDATOR_EMAIL } from "../Validators";
import { useHttpRequest } from "../Hooks/request-hook";
import { useForm } from "../Hooks/form-hook";
import { AuthContext } from "../Context/auth-context";

import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

const theme = createTheme();

const init = {
  email: "",
  password: "",
};

const LoginWindow = () => {
  const [isAdmin, setIsAdmin] = React.useState(false);
  const { isLoading, error, request } = useHttpRequest();
  const [errorMsg, setErrorMsg] = React.useState("");
  const auth = useContext(AuthContext);
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      temp.email = /^\S+@\S+\.\S+$/.test(fieldValues.email)
        ? ""
        : "Podaj poprawny adres email";
    }
    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : "Podaj hasło";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let type;
    if (validate()) {
      if (isAdmin) {
        type = "admin";
      } else {
        type = "user";
      }
      if (type === "user") {
        try {
          const response = await request(
            "/user/signin/user",
            "POST",
            JSON.stringify({
              email: values.email,
              password: values.password,
            }),
            { "Content-Type": "application/json" }
          );
          //auth.login(response.userId, response.token);
          auth.login(response.id, response.admin, response.token);
        } catch (err) {
          setErrorMsg(
            "Błąd logowania, wprowadzono niepoprawne dane lub konto jest zablokowane."
          );
        }
      }
      if (type === "admin") {
        try {
          const response = await request(
            "/user/signin/admin",
            "POST",
            JSON.stringify({
              email: values.email,
              password: values.password,
            }),
            { "Content-Type": "application/json" }
          );
          auth.login(response.id, response.admin, response.token);
          //auth.login(response.userId, response.token);
        } catch (err) {
          setErrorMsg(
            "Błąd logowania, wprowadzono niepoprawne dane lub konto jest zablokowane."
          );
        }
      }
    }
  };
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    init,
    true,
    validate
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Logowanie
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email}
              helperText={errors.email}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              value={values.password}
              onChange={handleInputChange}
              error={errors.password}
              helperText={errors.password}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => {
                    setIsAdmin(!isAdmin);
                  }}
                />
              }
              label="Loguje się jako administrator"
            />

            {/* <Select
              labelId="accounttype"
              id="accounttype"
              fullWidth
              value={type}
              onChange={handleChange}
            >
              <MenuItem value="user">Użytkownik</MenuItem>
              <MenuItem value="admin">Administrator</MenuItem>
            </Select> */}
            {errorMsg && (
              <Alert
                variant="filled"
                severity="error"
                onClose={() => {
                  setErrorMsg("");
                }}
              >
                {errorMsg}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Zaloguj
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to="/remindpass" variant="body2">
                  Przypomnij hasło
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LoginWindow;
