import * as React from "react";
import {
  DataGrid,
  GridActionsCellItem,
  plPL,
  GridRowParams,
  GridRowId,
} from "@mui/x-data-grid";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Autocomplete } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useHttpRequest } from "../../Hooks/request-hook";
import { AuthContext } from "../../Context/auth-context";
import { useForm } from "../../Hooks/form-hook";
import ColumnMenu from "./ColumnMenu";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import "moment/locale/pl";
import Grid from "@mui/material/Grid";
import CheckIcon from "@mui/icons-material/Check";
import { validate } from "../../Validators";

const init = {
  instType: "",
  inst: "",
  amount: "",
  answer: "",
  note: "",
  instTypeEdit: "",
  instEdit: "",
  amountEdit: "",
  answerEdit: "",
  noteEdit: "",
};

// const useApiRef = () => {
//   const apiRef = React.useRef(null);
//   const _columns = React.useMemo(
//     () =>
//       columns.concat({
//         field: "Ref",
//         width: 50,
//         renderCell: (params) => {
//           apiRef.current = params.api;
//           params.api.getRowModels((x) => {
//             console.log(x);
//           });
//           return null;
//         },
//       }),
//     [columns]
//   );

//   return { apiRef, columns: _columns };
// };

export default function MeetingApplications(props) {
  moment.locale("pl");
  const auth = React.useContext(AuthContext);
  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(init);
  const { isLoading, error, request } = useHttpRequest();
  const [tableDataBanks, setTableDataBanks] = React.useState([]);
  const [tableDataInst, setTableDataInst] = React.useState([]);
  const [inst, setInst] = React.useState([]);
  const [banks, setBanks] = React.useState([]);
  const [loadedBanks, setLoadedBanks] = React.useState(false);
  const [loadedInst, setLoadedInst] = React.useState(false);
  const [app, setApp] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const [meeting, setMeeting] = React.useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [date, setDate] = React.useState(Date.now());
  const [inputInst, setInputInst] = React.useState("");
  const [inputInstEdit, setInputInstEdit] = React.useState("");
  const [dateEdit, setDateEdit] = React.useState(null);
  const [note, setNote] = React.useState();
  const [errorAdd, setErrorAdd] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [preBanksTable, setPreBanksTable] = React.useState([]);
  const [preInstTable, setPreInstTable] = React.useState([]);
  const [preData, setPreData] = React.useState([]);
  let adderror = false;
  let changed = false;
  // const { apiRef, columns } = useApiRef();

  const checkIfValid = (tableDataBanks, tableDataInst) => {
    let count = 0;
    let countpre = 0;
    preData.forEach((x) => {
      if (x.answer !== "") {
        countpre = countpre + 1;
      }
    });
    tableDataInst.forEach((x) => {
      if (x.answer !== "") {
        count = count + 1;
      }
    });
    tableDataBanks.forEach((x) => {
      if (x.answer !== "") {
        count = count + 1;
      }
    });
    if (count > countpre || changed) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const updateRowsBanks = (value, id, field) => {
    // const item = tableDataBanks.find((item) => item.id === id);
    // item[field] = value;
    let arr = [...tableDataBanks];
    if (field === "answer") arr[id - 1].answer = value;
    if (field === "date") arr[id - 1].date = value;
    if (field === "amount") arr[id - 1].amount = value;
    if (field === "note") arr[id - 1]._note = value;
    checkIfValid(arr, tableDataInst);
    setTableDataBanks(arr);
  };

  const updateRowsInst = (value, id, field) => {
    let arr = [...tableDataInst];
    if (field === "answer") arr[id - 1].answer = value;
    if (field === "date") arr[id - 1].date = value;
    if (field === "amount") arr[id - 1].amount = value;
    if (field === "note") arr[id - 1]._note = value;
    checkIfValid(tableDataBanks, arr);
    setTableDataInst(arr);
  };

  const mapData = (data, inst, banks) => {
    let iteration = 0;
    const resultbanks = banks.map((x) => {
      const dbdata = data.filter((y) => {
        return y.inst === x.name && y.instType === "Bank";
      });

      let date;
      let note;

      if (dbdata[0]) {
        if (dbdata[0].date) {
          date = moment(dbdata[0].date).format("DD.MM.YYYY");
        } else {
          date = "";
        }

        if (dbdata[0].note) {
          note = dbdata[0].note;
        } else {
          note = "";
        }

        iteration = iteration + 1;

        return {
          id: iteration,
          meetingid: dbdata[0].meetingid,
          _id: dbdata[0].id,
          date: dbdata[0].date,
          _date: dbdata[0].date,
          inst: x.name,
          _instType: "Bank",
          amount: dbdata[0].amount,
          _note: note,
          answer: dbdata[0].answer,
          error: "",
        };
      } else {
        date = "";
        note = "";
        iteration = iteration + 1;

        return {
          id: iteration,
          meetingid: "",
          _id: "",
          date: date,
          _date: "",
          inst: x.name,
          _instType: "Bank",
          amount: "",
          _note: note,
          answer: "",
          error: "",
        };
      }
    });

    iteration = 0;

    setLoadedBanks(true);
    setTableDataBanks(resultbanks);

    const resultinst = inst.map((x) => {
      const dbdata = data.filter((y) => {
        return y.inst === x.name && y.instType === "Instytucja pozabankowa";
      });
      let date;
      let note;
      if (dbdata[0]) {
        if (dbdata[0].date) {
          date = moment(dbdata[0].date).format("DD.MM.YYYY");
        } else {
          date = "";
        }

        if (dbdata[0].note) {
          note = dbdata[0].note;
        } else {
          note = "";
        }

        iteration = iteration + 1;

        return {
          id: iteration,
          meetingid: dbdata[0].meetingid,
          _id: dbdata[0].id,
          date: dbdata[0].date,
          _date: dbdata[0].date,
          inst: x.name,
          _instType: "Instytucja pozabankowa",
          amount: dbdata[0].amount,
          _note: note,
          answer: dbdata[0].answer,
        };
      } else {
        date = "";
        note = "";
        iteration = iteration + 1;

        return {
          id: iteration,
          meetingid: "",
          _id: "",
          date: date,
          _date: "",
          inst: x.name,
          _instType: "Instytucja pozabankowa",
          amount: "",
          _note: note,
          answer: "",
        };
      }
    });

    setLoadedInst(true);
    setTableDataInst(resultinst);
    // setPreBanksTable(resultbanks);
    // setPreInstTable(resultinst);
  };

  React.useEffect(() => {
    const call = async () => {
      try {
        const data = await request(
          `/meeting/applications/${props.id}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        mapData(data.applications, data.institutions, data.banks);
        setPreData(data.applications);
        setInst(data.institutions);
        setBanks(data.banks);
        setMeeting(data.meeting);
      } catch (err) {}
    };
    call();
  }, [request]);

  const validateEdit = (fieldValues = values) => {
    let temp = { ...errors };
    if ("instTypeEdit" in fieldValues) {
      temp.instTypeEdit = fieldValues.instTypeEdit
        ? ""
        : "Wybierz poprawny typ instytucji";
    }
    if ("instEdit" in fieldValues) {
      temp.instEdit = fieldValues.instEdit ? "" : "Wybierz instytucję";
    }
    if ("answerEdit" in fieldValues) {
      temp.answerEdit = fieldValues.answerEdit ? "" : "Wybierz odpowiedź";
    }
    setErrors({
      ...temp,
    });
    if (fieldValues == values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateBanks = (data) => {
    data.map((x, index) => {
      // console.log(!x.note);
      // console.log(x.amount);
      // console.log(x.status);
      let arr = [...tableDataBanks];
      arr[index].error = false;
      if (x.amount !== "" || x.note) {
        if (!x.answer) {
          arr[index].error = true;
          adderror = true;
        }
      }
      if (x.answer && x.date === "") {
        const datenow = new Date();
        //let date = moment(datenow).format("DD-MM-YYYY");
        arr[index].date = datenow;
      }
      setTableDataBanks(arr);
    });
  };

  const validateInst = (data) => {
    data.map((x, index) => {
      // console.log(!x.note);
      // console.log(x.amount);
      // console.log(x.status);
      let arr = [...tableDataInst];
      arr[index].error = false;
      if (x.amount !== "" || x.note) {
        if (!x.answer) {
          arr[index].error = true;
          setTableDataInst(arr);
          adderror = true;
        }
      }
      if (x.answer && x.date === "") {
        const datenow = new Date();
        //let date = moment(datenow).format("DD-MM-YYYY");
        arr[index].date = datenow;
      }
    });
  };

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  // const handleSubmitEdit = async (event) => {
  //   event.preventDefault();
  //   setErrors({});
  //   if (validateEdit() || true) {
  //     try {
  //       const response = await request(
  //         `/meeting/${props.id}/updateapplication/${app.id}`,
  //         "PATCH",
  //         JSON.stringify({
  //           answer: values.answerEdit,
  //           amount: values.amountEdit,
  //           note: values.noteEdit,
  //           instType: values.instTypeEdit,
  //           inst: values.instEdit,
  //           date: dateEdit,
  //         }),
  //         {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + auth.token,
  //         }
  //       );
  //       window.location.reload();
  //       handleCloseEdit();
  //     } catch (err) {
  //       setErrorMsg(
  //         "Nie można edytować wniosku, sprawdź poprawność wprowadzonych danych."
  //       );
  //       handleCloseEdit();
  //     }
  //   }
  // };

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleSubmitAdd = async (event) => {
    setDisabled(true);
    event.preventDefault();
    setErrors({});
    const apidata = tableDataBanks.concat(tableDataInst);
    adderror = false;
    validateBanks(tableDataBanks);
    validateInst(tableDataInst);
    if (!adderror) {
      try {
        const response = await request(
          `/meeting/newapplication/${props.id}/${props.client}`,
          "POST",
          JSON.stringify(apidata),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setMsg("Wnioski zostały dodane.");
        setErrorMsg("");
      } catch (err) {
        setMsg("");
        setErrorMsg(
          "Nie można dodać wniosków, sprawdź poprawność wprowadzonych danych."
        );
      }
      try {
        const response = await request(
          "/clients/activity/update",
          "PATCH",
          JSON.stringify({ id: props.client }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {
        //
      }
    } else {
      setMsg("");
      setErrorMsg(
        "Nie można dodać wniosków, uzupełnij wymagane pole 'Status'."
      );
    }
  };

  const columns = [
    { field: "meetingid", headerName: "Spotkanie", flex: 1 },
    { field: "_id", headerName: "_ID", width: 0 },
    {
      field: "date",
      headerName: "Data złożenia",
      type: "date",
      flex: 1,
      minWidth: 160,
      renderCell: (params) => {
        return (
          <TextField
            onClick={(event) => {
              event.ignore = true;
            }}
            type="date"
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            style={{
              borderBottom: "1px dashed rgba(0,0,0,0.3)",
              borderColor: "black",
            }}
            value={
              moment(params.value).format("YYYY-MM-DD") ||
              moment(Date.parse(params.row.date)).format("YYYY-MM-DD")
            }
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              if (params.row.answer !== "") {
                changed = true;
              }
              if (params.row._instType === "Bank") {
                updateRowsBanks(e.target.value, params.row.id, params.field);
              } else {
                updateRowsInst(e.target.value, params.row.id, params.field);
              }
            }}
          />
        );
      },
    },
    { field: "_date", headerName: "_DATE", type: "date" },
    { field: "inst", headerName: "Nazwa instytucji", flex: 2, minWidth: 200 },
    { field: "_instType", headerName: "_INSTTYPE", width: 0 },
    {
      field: "amount",
      headerName: "Kwota",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <TextField
          fullWidth
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          style={{
            borderBottom: "1px dashed rgba(0,0,0,0.3)",
            borderColor: "black",
          }}
          onClick={(event) => {
            event.ignore = true;
          }}
          value={params.value}
          onChange={(e) => {
            if (params.row.answer !== "") {
              changed = true;
            }
            if (params.row._instType === "Bank") {
              updateRowsBanks(e.target.value, params.row.id, params.field);
            } else {
              updateRowsInst(e.target.value, params.row.id, params.field);
            }
          }}
        />
      ),
    },
    {
      field: "answer",
      headerName: "Status*",

      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <FormControl fullWidth>
          {/* <InputLabel>Odpowiedź</InputLabel> */}
          <Select
            variant="standard"
            disableUnderline
            style={{
              borderBottom: "1px dashed rgba(0,0,0,0.3)",
              borderColor: "black",
            }}
            label="Odpowiedź"
            onClick={(event) => {
              event.ignore = true;
            }}
            value={params.value}
            onChange={(e) => {
              if (e.target.value !== "") changed = true;
              if (params.row._instType === "Bank") {
                updateRowsBanks(e.target.value, params.row.id, params.field);
              } else {
                updateRowsInst(e.target.value, params.row.id, params.field);
              }
            }}
          >
            <MenuItem value="">Brak</MenuItem>
            <MenuItem value="Wysłano">Wysłano</MenuItem>
            <MenuItem value="Oferta">Oferta</MenuItem>
            <MenuItem value="Odmowa">Odmowa</MenuItem>
            <MenuItem value="Wypłacono">Wypłacono</MenuItem>
            <MenuItem value="Nie wysłano">Nie wysłano</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    { field: "_note", headerName: "_NOTE", width: 0 },
    // {
    //   field: "actions",
    //   headerName: "Akcja",
    //   flex: 1,
    //   minWidth: 60,
    //   type: "actions",
    //   getActions: (GridRowParams) => [
    //     <GridActionsCellItem
    //       label="Zobacz szczegóły"
    //       onClick={() => {
    //         setDateEdit(GridRowParams.getValue(GridRowParams.id, "_date"));
    //         setApp({
    //           id: GridRowParams.getValue(GridRowParams.id, "_id"),
    //           date: GridRowParams.getValue(GridRowParams.id, "date"),
    //         });
    //         values.instEdit = GridRowParams.getValue(GridRowParams.id, "inst");
    //         setInputInstEdit(GridRowParams.getValue(GridRowParams.id, "inst"));
    //         values.instTypeEdit = GridRowParams.getValue(
    //           GridRowParams.id,
    //           "_instType"
    //         );
    //         values.amountEdit = GridRowParams.getValue(
    //           GridRowParams.id,
    //           "amount"
    //         );
    //         values.answerEdit = GridRowParams.getValue(
    //           GridRowParams.id,
    //           "answer"
    //         );
    //         values.noteEdit = GridRowParams.getValue(GridRowParams.id, "_note");
    //         handleClickOpenEdit();
    //       }}
    //       showInMenu
    //     />,
    //     <GridActionsCellItem
    //       label="Usuń"
    //       onClick={async () => {
    //         try {
    //           const response = await request(
    //             `/meeting/application/delete/${GridRowParams.getValue(
    //               GridRowParams.id,
    //               "_id"
    //             )}`,
    //             "DELETE",
    //             null,
    //             {
    //               "Content-Type": "application/json",
    //               Authorization: "Bearer " + auth.token,
    //             }
    //           );
    //           window.location.reload();
    //           setErrorMsg("");
    //         } catch (err) {
    //           setErrorMsg("Wystąpił błąd, spróbuj ponownie później.");
    //         }
    //       }}
    //       showInMenu
    //     />,
    //   ],
    // },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Grid container direction={"row"} alignItems={"center"}>
        <Grid item xs={12} md={8}>
          <h2>Wnioski: lista banków</h2>
        </Grid>
      </Grid>
      <Box
        sx={{
          "& .noerror": {
            borderBottom: 2,
            //borderTop: 1,
            borderColor: "rgba(0,0,0.7)",
          },
          "& .error": {
            backgroundColor: "rgba(243, 12, 12, 1)",
            borderBottom: 2,
            //borderTop: 1,
            borderColor: "rgba(0,0,0.7)",
          },
          "& .font": {
            fontWeight: "bold",
          },
        }}
      >
        {loadedBanks && (
          <DataGrid
            autoHeight
            autoWidth
            rows={tableDataBanks}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            getRowClassName={(params) => {
              if (params.row.error === true) return "error";
              else return "noerror";
            }}
            getCellClassName={(params) => {
              if (params.field === "inst") {
                return "font";
              }
            }}
            onRowClick={(data, event) => {
              if (!event.ignore) {
                setApp({
                  id: data.row.id,
                  inst: "Bank",
                });
                setNote(data.row._note);
                handleClickOpenEdit();
              }
            }}
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            columnVisibilityModel={{
              meetingid: false,
              _id: false,
              _note: false,
              _instType: false,
              _date: false,
            }}
            components={{
              ColumnMenu: ColumnMenu,
            }}
          />
        )}
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid item xs={12} md={8}>
            <h2>Wnioski: lista instytucji pozabankowych</h2>
          </Grid>
        </Grid>

        {loadedInst && (
          <DataGrid
            autoHeight
            autoWidth
            rows={tableDataInst}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            getRowClassName={(params) => {
              if (params.row.error === true) return "error";
              else return "noerror";
            }}
            getCellClassName={(params) => {
              if (params.field === "inst") {
                return "font";
              }
            }}
            onRowClick={(data, event) => {
              if (!event.ignore) {
                setApp({
                  id: data.row.id,
                  inst: "Instytucja pozabankowa",
                });
                setNote(data.row._note);
                handleClickOpenEdit();
              }
            }}
            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
            columnVisibilityModel={{
              meetingid: false,
              _id: false,
              _note: false,
              _instType: false,
              _date: false,
            }}
            components={{
              ColumnMenu: ColumnMenu,
            }}
          />
        )}
      </Box>
      <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth>
        <DialogTitle>Szczegóły wniosku</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            margin="dense"
            id="outlined-multiline-flexible"
            label="Notatka"
            name="noteEdit"
            fullWidth
            variant="standard"
            value={note || ""}
            onChange={(e) => {
              setNote(e.target.value);
              checkIfValid();
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (app.answer !== "") {
                changed = true;
              }
              if (app.inst === "Bank") {
                updateRowsBanks(note, app.id, "note");
              } else {
                updateRowsInst(note, app.id, "note");
              }
              handleCloseEdit();
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {errorMsg && (
        <Alert
          variant="filled"
          severity="error"
          onClose={() => {
            setErrorMsg("");
          }}
        >
          {errorMsg}
        </Alert>
      )}
      {msg && (
        <Alert
          variant="filled"
          severity="success"
          onClose={() => {
            setMsg("");
          }}
        >
          {msg}
        </Alert>
      )}
      <Grid container direction={"row"} alignItems={"center"} spacing={2}>
        <Grid item xs={0} md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Button
            margin="normal"
            fullWidth
            type="submit"
            variant="contained"
            size="big"
            sx={{
              marginTop: "10px",
            }}
            onClick={handleSubmitAdd}
            startIcon={<CheckIcon />}
            disabled={disabled}
          >
            Zapisz
          </Button>
        </Grid>
        <Grid item xs={0} md={3}></Grid>
      </Grid>
    </div>
  );
}
